import { createSelector } from "reselect";

export const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector([selectUser], (user) => {
  // console.log(`${user} checker`);
  return user.currentUser;
});

export const isLoading = createSelector([selectUser], (user) => user.isLoading);
//sign in
export const signInLoading = createSelector([selectUser], (user) => {
  return user.isLoading;
});

export const signInError = createSelector(
  [selectUser],
  (user) => user.signInError
);

export const signInSuccess = createSelector(
  [selectUser],
  (user) => user.signInSuccess
);

//signup
export const signUpLoading = createSelector(
  [selectUser],
  (user) => user.isLoading
);

export const signUpError = createSelector(
  [selectUser],
  (user) => user.signUpError
);

export const signUpSuccess = createSelector(
  [selectUser],
  (user) => user.signUpSuccess
);

//verify
export const verifyEmailLoading = createSelector(
  [selectUser],
  (user) => user.isLoading
);

export const verifyEmailError = createSelector(
  [selectUser],
  (user) => user.verifyEmailError
);

export const verifyEmailSuccess = createSelector(
  [selectUser],
  (user) => user.verifyEmailSuccess
);

//forgot password
export const forgotPasswordLoading = createSelector(
  [selectUser],
  (user) => user.isLoading
);

export const forgotPasswordError = createSelector(
  [selectUser],
  (user) => user.forgotPasswordError
);

export const forgotPasswordSuccess = createSelector(
  [selectUser],
  (user) => user.forgotPasswordSuccess
);
//reset password
export const resetPasswordLoading = createSelector(
  [selectUser],
  (user) => user.isLoading
);

export const resetPasswordError = createSelector(
  [selectUser],
  (user) => user.resetPasswordError
);

export const resetPasswordSuccess = createSelector(
  [selectUser],
  (user) => user.resetPasswordSuccess
);
export const editProfileError = createSelector(
  [selectUser],
  (user) => user.editProfileError
);

export const editProfileSuccess = createSelector(
  [selectUser],
  (user) => user.editProfileSuccess
);

export const editProfileLoading = createSelector(
  [selectUser],
  (user) => user.editProfileLoading
);

export const accessToken = createSelector(
  [selectUser],
  (user) => user.accessToken
);
export const refreshToken = createSelector(
  [selectUser],
  (user) => user.refreshToken
);
