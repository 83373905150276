import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";
import { PersistGate } from "redux-persist/integration/react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";
import { QueryClient, QueryClientProvider } from "react-query";
// import { registerServiceWorker } from "./serviceWorker";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </PersistGate>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

reportWebVitals();
