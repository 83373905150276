export const menuItemsAdmin = {
  items: [
    {
      id: "admin",
      title: "",
      type: "group",
      icon: "icon-support",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/",
          classes: "nav-item ",
          icon: "feather icon-home",
        },
        {
          id: "user-management",
          title: "User Mangement",
          type: "collapse",
          classes: "nav-item",
          icon: "feather icon-server",
          children: [
            {
              id: "all",
              title: "All Users",
              type: "item",
              url: "/user-management",
            },
            {
              id: "banned",
              title: "Banned Users",
              type: "item",
              url: "/user-management/banned",
            },
          ],
        },
        // not a needed feature now
        // {
        //   id: "customers-rank",
        //   title: "Customers Rank",
        //   type: "item",
        //   url: "/customers-rank",
        //   classes: "nav-item",
        //   icon: "feather icon-user",
        // },
        {
          id: "access",
          title: "Access Mangement",
          type: "item",
          url: "/access-management",
          classes: "nav-item",
          icon: "feather icon-lock",
        },
        {
          id: "category",
          title: "Category Mangement",
          type: "item",
          url: "/category-management",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },
        {
          id: "rates",
          title: "Rates Mangement",
          type: "item",
          url: "/rates-management",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "promoCodes",
          title: "Promo Codes",
          type: "item",
          url: "/promo-codes",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        // {
        //   id: "giftcodes",
        //   title: "Giftcodes",
        //   type: "item",
        //   url: "/giftcodes",
        //   classes: "nav-item",
        //   icon: "feather icon-book",
        // },
        {
          id: "topUp",
          title: "Top Up",
          type: "item",
          url: "/topup",
          classes: "nav-item",
          icon: "feather icon-book",
        },

        {
          id: "refill",
          title: "Refill",
          type: "item",
          url: "/refill",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "referrals",
          title: "Referrals Management",
          type: "item",
          url: "/referrals",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "transaction",
          title: " Transaction Mangement",
          type: "collapse",
          url: "/transaction-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "gift-card",
              title: "Gift Card",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Orders",
                  type: "item",
                  url: "/giftcard-transaction-management/all",
                },
                {
                  id: "completed",
                  title: "Completed",
                  url: "/giftcard-transaction-management/completed",
                  type: "item",
                },
                {
                  id: "pending",
                  title: "Pending",
                  url: "/giftcard-transaction-management/pending",
                  type: "item",
                },
                {
                  id: "rejected",
                  title: "Rejected",
                  url: "/giftcard-transaction-management/rejected",
                  type: "item",
                },
              ],
            },

            {
              id: "crypto",
              title: "Crypto",
              url: "/crypto-transaction-management",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Orders",
                  type: "item",
                  url: "/crypto-transaction-management/all",
                },
                {
                  id: "processed",
                  title: "Processed",
                  url: "/crypto-transaction-management/processed",
                  type: "item",
                },
                {
                  id: "pending",
                  title: "Pending",
                  url: "/crypto-transaction-management/pending",
                  type: "item",
                },
                {
                  id: "proofs",
                  title: "Proofs",
                  url: "/crypto-transaction-management/proofs",
                  type: "item",
                },
              ],
            },

            {
              id: "swap",
              title: "Swap Transactions",
              type: "item",
              url: "/swap",
              classes: "nav-item",
              icon: "feather icon-book",
            },

            // {
            //   id: "rejection-reason",
            //   title: "Rejection Reason",
            //   type: "item",
            //   url: "/transaction-management/rejection-reason",
            // },
          ],
        },

        {
          id: "payment",
          title: "Payment Mangement",
          type: "collapse",
          url: "/payment-management",
          classes: "nav-item",
          icon: "feather icon-clipboard",
          children: [
            {
              id: "pending",
              title: "Pending",
              url: "/payment-management/naira/pending",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",
              url: "/payment-management/naira/rejected",
              type: "item",
            },
            {
              id: "approved",
              title: "Approved",
              url: "/payment-management/naira/approved",
              type: "item",
            },
            {
              id: "withdrawal-requests",
              title: "Withdrawal Requests",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Withdrawals",
                  type: "item",
                  url: "/withdrawal/requests/all",
                },
                {
                  id: "pending",
                  title: "Pending",
                  type: "item",
                  url: "/withdrawal/requests/pending",
                },
                {
                  id: "approved",
                  title: "Processed",
                  url: "/withdrawal/requests/processed",
                  type: "item",
                },
                {
                  id: "declined",
                  title: "Declined",
                  url: "/withdrawal/requests/declined",
                  type: "item",
                },
              ],
            },

            {
              id: "paypal-requests",
              title: "Paypal Requests",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Requests",
                  type: "item",
                  url: "/paypal/requests/all",
                },
                {
                  id: "pending",
                  title: "Pending",
                  type: "item",
                  url: "/paypal/requests/pending",
                },
                {
                  id: "approved",
                  title: "Processed",
                  url: "/paypal/requests/processed",
                  type: "item",
                },
                {
                  id: "declined",
                  title: "Declined",
                  url: "/paypal/requests/declined",
                  type: "item",
                },
              ],
            },

            {
              id: "cashapp-requests",
              title: "CashApp Requests",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Requests",
                  type: "item",
                  url: "/cashapp/requests/all",
                },
                {
                  id: "pending",
                  title: "Pending",
                  type: "item",
                  url: "/cashapp/requests/pending",
                },
                {
                  id: "approved",
                  title: "Processed",
                  url: "/cashapp/requests/processed",
                  type: "item",
                },
                {
                  id: "declined",
                  title: "Declined",
                  url: "/cashapp/requests/declined",
                  type: "item",
                },
              ],
            },

            {
              id: "usdt-requests",
              title: "USDT Requests",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Requests",
                  type: "item",
                  url: "/usdt/requests/all",
                },
                {
                  id: "pending",
                  title: "Pending",
                  type: "item",
                  url: "/usdt/requests/pending",
                },
                {
                  id: "approved",
                  title: "Processed",
                  url: "/usdt/requests/processed",
                  type: "item",
                },
                {
                  id: "declined",
                  title: "Declined",
                  url: "/usdt/requests/declined",
                  type: "item",
                },
              ],
            },
          ],
        },

        {
          id: "contact",
          title: "Contact Users",
          type: "item",
          url: "/contact-users",
          classes: "nav-item",
          icon: "feather icon-file-text",
        },
        {
          id: "settings",
          title: "Settings",
          type: "item",
          url: "/settings",
          classes: "nav-item",
          icon: "feather icon-sliders",
        },
        {
          id: "logout",
          title: "Logout",
          type: "item",
          url: "/logout",
          classes: "nav-item",
          icon: "feather icon-user",
        },
      ],
    },
  ],
};

export const menuItemsPayment = {
  items: [
    {
      id: "admin",
      title: "",
      type: "group",
      icon: "icon-support",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/",
          classes: "nav-item ",
          icon: "feather icon-home",
        },

        {
          id: "user-management",
          title: "User Mangement",
          type: "item",
          url: "/user-management",
          classes: "nav-item",
          icon: "feather icon-server",
        },

        {
          id: "category",
          title: "Category Mangement",
          type: "item",
          url: "/category-management",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },

        {
          id: "topUp",
          title: "Top Up",
          type: "item",
          url: "/topup",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "refill",
          title: "Refill",
          type: "item",
          url: "/refill",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "rates",
          title: "Rates Mangement",
          type: "item",
          url: "/rates-management",
          classes: "nav-item",
          icon: "feather icon-book",
        },

        {
          id: "payment",
          title: "Payment Mangement",
          type: "collapse",
          url: "/payment-management",
          classes: "nav-item",
          icon: "feather icon-clipboard",
          children: [
            {
              id: "pending",
              title: "Pending",
              url: "/payment-management/naira/pending",
              type: "item",
            },
            {
              id: "rejected",
              title: "Rejected",

              url: "/payment-management/naira/rejected",
              type: "item",
            },
            {
              id: "approved",
              title: "Approved",

              url: "/payment-management/naira/approved",
              type: "item",
            },
          ],
        },
        {
          id: "logout",
          title: "Logout",
          type: "item",
          url: "/logout",
          classes: "nav-item",
          icon: "feather icon-user",
        },
      ],
    },
  ],
};

export const menuItemsTransactions = {
  items: [
    {
      id: "admin",
      title: "",
      type: "group",
      icon: "icon-support",
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          url: "/",
          classes: "nav-item ",
          icon: "feather icon-home",
        },

        {
          id: "user-management",
          title: "User Mangement",
          type: "item",
          url: "/user-management",
          classes: "nav-item",
          icon: "feather icon-server",
        },

        {
          id: "category",
          title: "Category Mangement",
          type: "item",
          url: "/category-management",
          classes: "nav-item",
          icon: "feather icon-sidebar",
        },

        {
          id: "topUp",
          title: "Top Up",
          type: "item",
          url: "/topup",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "refill",
          title: "Refill",
          type: "item",
          url: "/refill",
          classes: "nav-item",
          icon: "feather icon-book",
        },

        {
          id: "rates",
          title: "Rates Mangement",
          type: "item",
          url: "/rates-management",
          classes: "nav-item",
          icon: "feather icon-book",
        },
        {
          id: "transaction",
          title: " Transaction Mangement",
          type: "collapse",
          url: "/transaction-management",
          classes: "nav-item",
          icon: "feather icon-file-plus",
          children: [
            {
              id: "gift-card",
              title: "Gift Card",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Orders",
                  type: "item",
                  url: "/giftcard-transaction-management/all",
                },
                {
                  id: "completed",
                  title: "Completed",
                  url: "/giftcard-transaction-management/completed",
                  type: "item",
                },
                {
                  id: "pending",
                  title: "Pending",
                  url: "/giftcard-transaction-management/pending",
                  type: "item",
                },
                {
                  id: "rejected",
                  title: "Rejected",
                  url: "/giftcard-transaction-management/rejected",
                  type: "item",
                },
              ],
            },

            {
              id: "crypto",
              title: "Crypto",
              url: "/crypto-transaction-management",
              type: "collapse",
              classes: "nav-item",
              icon: "feather icon-file-plus",
              children: [
                {
                  id: "all",
                  title: "All Orders",
                  type: "item",
                  url: "/crypto-transaction-management/all",
                },
              ],
            },

            // {
            //   id: "rejection-reason",
            //   title: "Rejection Reason",
            //   type: "item",
            //   url: "/transaction-management/rejection-reason",
            // },
          ],
        },
        {
          id: "logout",
          title: "Logout",
          type: "item",
          url: "/logout",
          classes: "nav-item",
          icon: "feather icon-user",
        },
      ],
    },
  ],
};
