import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";
import AuthGuard from "./components/Auth/AuthGuard";
import {
  selectCurrentUser,
  accessToken,
} from "./store/selectors/auth.selector";
import { useSelector } from "react-redux";
import { BASE_URL } from "./libs";

const PermissionWrapper = ({ children, permissonLevel, ignore }) => {
  const user = useSelector(selectCurrentUser);
  const levels = {
    Super: 3,
    subAdmin: 2,
    users: 1,
  };

  return !accessToken && !user?.role ? (
    <Redirect to="/login" />
  ) : user?.role && user?.role !== "user" ? (
    children
  ) : user?.role && levels[user?.role] <= permissonLevel ? (
    <Redirect to="/404" />
  ) : (
    <Redirect to="/login" />
  );
};

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) =>
              route.ignore ? (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              ) : (
                <PermissionWrapper
                  key={i}
                  permissonLevel={route?.permissonLevel ?? 1}
                >
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                </PermissionWrapper>
              )
            }
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("./views/Login")),
    ignore: true,
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./views/errors/NotFound404")),
    ignore: true,
  },

  {
    path: "*",
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/Dashboard")),
        ignore: true,
      },
      {
        exact: true,
        path: "/settings",
        component: lazy(() => import("./views/Settings")),
        permissonLevel: 3,
      },
      {
        exact: true,
        path: "/logout",
        component: lazy(() => import("./views/Logout")),
      },
      {
        exact: true,
        path: "/contact-users",
        component: lazy(() => import("./views/Contact-user")),
        permissonLevel: 3,
      },

      {
        exact: true,
        path: "/rates-management",
        component: lazy(() => import("./views/Rates-management")),
      },

      {
        exact: true,
        path: "/referrals",
        component: lazy(() => import("./views/Referrals/allreferrals")),
      },

      {
        exact: true,
        path: "/promo-codes",
        component: lazy(() => import("./views/Promo-Codes")),
      },

      {
        exact: true,
        path: "/user-management/banned",
        component: lazy(() => import("./views/User-management/banned")),
      },

      {
        exact: true,
        path: "/access-management",
        component: lazy(() => import("./views/Access-management")),
        permissonLevel: 3,
      },
      {
        exact: true,
        path: "/category-management",
        component: lazy(() => import("./views/Category-management")),
      },
      //user related pages
      {
        exact: true,
        path: "/user-management",
        component: lazy(() => import("./views/User-management")),
      },

      // {
      //   exact: true,
      //   path: "/customers-rank",
      //   component: lazy(() => import("./views/CustomersRank")),
      // },

      {
        exact: true,
        path: "/user-management/:userId",
        component: lazy(() => import("./views/User-management/user-page")),
      },

      //naira payment
      {
        exact: true,
        path: "/payment-management/naira/pending",
        component: lazy(() => import("./views/PaymentManagment/Naira/pending")),
      },
      {
        exact: true,
        path: "/payment-management/naira/approved",
        component: lazy(() =>
          import("./views/PaymentManagment/Naira/approved")
        ),
      },
      // {
      //   exact: true,
      //   path: "/payment-management/naira/others",
      //   component: lazy(() => import("./views/PaymentManagment/Naira/others")),
      // },
      {
        exact: true,
        path: "/payment-management/naira/rejected",
        component: lazy(() =>
          import("./views/PaymentManagment/Naira/rejected")
        ),
      },

      // Rejection Reason
      {
        exact: true,
        path: "/Giftcard-transaction-mangement/rejection-reason",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Giftcard-transaction-mangement/rejectionReason"
          )
        ),
      },

      {
        exact: true,
        path: "/swap",
        component: lazy(() =>
          import("./views/Transaction-management/Swap-Transaction-Management")
        ),
      },

      // giftcard transaction management

      {
        exact: true,
        path: "/giftcard-transaction-management/all",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Giftcard-transaction-mangement/allorders"
          )
        ),
      },
      {
        exact: true,
        path: "/giftcard-transaction-management/pending",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Giftcard-transaction-mangement/pending"
          )
        ),
      },

      {
        exact: true,
        path: "/giftcard-transaction-management/completed",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Giftcard-transaction-mangement/completed"
          )
        ),
      },
      {
        exact: true,
        path: "/giftcard-transaction-management/rejected",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Giftcard-transaction-mangement/rejected"
          )
        ),
      },

      // withdrawal management
      {
        exact: true,
        path: "/withdrawal/requests/all",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Withdrawal-Request-Management/allwithdrawals"
          )
        ),
      },
      {
        exact: true,
        path: "/withdrawal/requests/pending",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Withdrawal-Request-Management/pending"
          )
        ),
      },

      {
        exact: true,
        path: "/withdrawal/requests/processed",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Withdrawal-Request-Management/processed"
          )
        ),
      },
      {
        exact: true,
        path: "/withdrawal/requests/declined",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Withdrawal-Request-Management/declined"
          )
        ),
      },

      // {
      //   exact: true,
      //   path: "/giftcodes",
      //   component: lazy(() => import("./views/Giftcodes")),
      //   permissonLevel: 3,
      // },

      // paypal requests
      {
        exact: true,
        path: "/paypal/requests/all",
        component: lazy(() =>
          import("./views/Transaction-management/Paypal-Request/allrequests")
        ),
      },
      {
        exact: true,
        path: "/paypal/requests/pending",
        component: lazy(() =>
          import("./views/Transaction-management/Paypal-Request/pending")
        ),
      },

      {
        exact: true,
        path: "/paypal/requests/processed",
        component: lazy(() =>
          import("./views/Transaction-management/Paypal-Request/processed")
        ),
      },
      {
        exact: true,
        path: "/paypal/requests/declined",
        component: lazy(() =>
          import("./views/Transaction-management/Paypal-Request/declined")
        ),
      },

      // cash app requests
      {
        exact: true,
        path: "/cashapp/requests/all",
        component: lazy(() =>
          import("./views/Transaction-management/CashApp-Request/allrequests")
        ),
      },
      {
        exact: true,
        path: "/cashapp/requests/pending",
        component: lazy(() =>
          import("./views/Transaction-management/CashApp-Request/pending")
        ),
      },

      {
        exact: true,
        path: "/cashapp/requests/processed",
        component: lazy(() =>
          import("./views/Transaction-management/CashApp-Request/processed")
        ),
      },
      {
        exact: true,
        path: "/cashapp/requests/declined",
        component: lazy(() =>
          import("./views/Transaction-management/CashApp-Request/declined")
        ),
      },

      // usdt requests
      {
        exact: true,
        path: "/usdt/requests/all",
        component: lazy(() =>
          import("./views/Transaction-management/Usdt-Request/allrequests")
        ),
      },
      {
        exact: true,
        path: "/usdt/requests/pending",
        component: lazy(() =>
          import("./views/Transaction-management/Usdt-Request/pending")
        ),
      },

      {
        exact: true,
        path: "/usdt/requests/processed",
        component: lazy(() =>
          import("./views/Transaction-management/Usdt-Request/processed")
        ),
      },
      {
        exact: true,
        path: "/usdt/requests/declined",
        component: lazy(() =>
          import("./views/Transaction-management/Usdt-Request/declined")
        ),
      },

      {
        exact: true,
        path: "/topup",
        component: lazy(() => import("./views/Topup")),
      },
      {
        exact: true,
        path: "/refill",
        component: lazy(() => import("./views/Refill")),
      },

      // crypto transaction management

      {
        exact: true,
        path: "/crypto-transaction-management/all",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Crypto-Transaction-Management/allorders/index"
          )
        ),
      },
      {
        exact: true,
        path: "/crypto-transaction-management/processed",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Crypto-Transaction-Management/processed"
          )
        ),
      },
      {
        exact: true,
        path: "/crypto-transaction-management/pending",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Crypto-Transaction-Management/pending"
          )
        ),
      },
      {
        exact: true,
        path: "/crypto-transaction-management/proofs",
        component: lazy(() =>
          import(
            "./views/Transaction-management/Crypto-Transaction-Management/proofs"
          )
        ),
      },

      {
        path: "*",
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
];

export default routes;
