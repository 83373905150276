import JJC from "../assets/images/jjc.png";
import JJCActive from "../assets/images/jjc_active.png";
import Chairman from "../assets/images/chairman.png";
import ChairmanActive from "../assets/images/chairman_active.png";
import Govonor from "../assets/images/governor.png";
import GovonorActive from "../assets/images/governor_active.png";
import Senator from "../assets/images/senator.png";
import SenatorActive from "../assets/images/senator_active.png";
import Presido from "../assets/images/presido.png";
import PresidoActive from "../assets/images/presido_active.png";

// export const baseUrl = `${process.env.REACT_APP_BACKEND_URL}`;
export const baseUrl = `https://api.1minutepay.online/api/v1/admin`;
// export const baseUrl = `http://oneminutepayapi-env.eba-insnrfkp.us-east-1.elasticbeanstalk.com/api/v1/admin`;

export const DATE_FORMAT = "MMM, dd yyyy";

// time format
export const TIME_FORMAT = "hh:m a";
export const BASENAME = "/"; // don't add '/' at end off BASENAME
export const BASE_URL = "/";
export const BASE_TITLE = " | 1MinutePay Admin";

export const CONFIG = {
  layout: "vertical", // vertical, horizontal
  subLayout: "", // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
  collapseMenu: false, // mini-menu
  layoutType: "menu-dark", // menu-dark, menu-light, dark
  navIconColor: false,
  headerBackColor: "header-default", // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
  navBackColor: "navbar-default", // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
  navBrandColor: "brand-default", // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
  navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
  rtlLayout: false,
  navFixedLayout: true, // only for vertical layouts
  headerFixedLayout: false, // only for vertical layouts
  boxLayout: false,
  navDropdownIcon: "style1", // style1, style2, style3
  navListIcon: "style1", // style1, style2, style3, style4, style5, style6
  navActiveListColor: "active-default", // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
  navListTitleColor: "title-default", // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
  navListTitleHide: false,
  configBlock: true,
  layout6Background:
    "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)", // used only for pre-layout = layout-6
  layout6BackSize: "", // 'auto' - for background pattern, 'cover' - for background images & used only for pre-layout = layout-6
};

export const levelClientArray = [
  {
    key: "jjc",
    image: JJC,
    activeImage: JJCActive,
  },
  {
    key: "chairman",
    desc: "After transaction 100K",
    image: Chairman,
    activeImage: ChairmanActive,
  },
  {
    key: "governor",
    desc: "After transaction 500K",
    image: Govonor,
    activeImage: GovonorActive,
  },
  {
    key: "senator",
    desc: "After transaction 1M",
    image: Senator,
    activeImage: SenatorActive,
  },
  {
    key: "presido",
    desc: "After transaction 10M",
    image: Presido,
    activeImage: PresidoActive,
  },
];
