import {
  signUpSuccess,
  signInSuccess,
  signUpFailure,
  signUpStart,
  setCurrentUser,
  setUserAccessToken,
  setUserRefreshToken,
  SignInStart,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signOutStart,
} from "../actions/auth.actions";
import { message } from "antd";
import Axios from "axios";

import { apiDelete, apiPost, Token } from "../../libs";

// creating sub admin
export const signUp = (user, handleClose) => {
  return async (dispatch) => {
    dispatch(signUpStart(user));
    try {
      const response = await apiPost(`staff/sub-admin`, user);
      if (response.data.status === "success") {
        dispatch(signUpSuccess(response.data.message));
        message.success(response.data.message || "Sub Admin Created");
        handleClose();
      } else {
        dispatch(signUpFailure());
        message.error(response.data.message);
      }
    } catch (e) {
      message.error(e.message || "Something went wrong");
      dispatch(signUpFailure(e));
    }
  };
};
// Sign in
export const signIn = (user) => {
  return async (dispatch) => {
    dispatch(SignInStart(user));
    try {
      const response = await apiPost(`auth/login`, user);
      if (response.statusCode === 200) {
        if (response.data.message === "Success" && response.data.data.role !== "user") {
          dispatch(signInSuccess());
          dispatch(setCurrentUser(response.data.data));
          dispatch(setUserAccessToken(response.data.token));
          // dispatch(setUserRefreshToken(response.data.user.refreshToken));
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        } else {
          message.error("User not authorized");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (e) {
      dispatch(signInFailure(e));
    }
  };
};

//signOut
export const signOut = (token) => {
  return async (dispatch) => {
    dispatch(signOutStart(token));
    try {
      const res = await apiDelete(`logout`, token);
      // console.log(res);
      if (res.statusCode === 200) {
        localStorage.clear();
        dispatch(signOutSuccess(res.data.message));
      }
    } catch (e) {
      // window.location.href = "/login";
      dispatch(signOutFailure(e));
    }
  };
};

Axios.interceptors.request.use((config) => {
  const accessToken = Token();
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

export const refreshToken = () => {
  return (dispatch, getState) => {
    const store = getState();
    const auth = store.auth;
    const accessToken = auth?.accessToken ?? "";
    const refresh = auth?.refreshToken ?? "";
    apiPost(`refresh`, { refreshToken: refresh }).then(({ data }) => {
      return data;
    });
  };
};
