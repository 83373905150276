import { actionTypes } from "redux-form";
import adminActionTypes from "../types/admin.type";

export const getUsersStart = (params, pageNum) => ({
  type: adminActionTypes.GET_USERS_START,
  payload: params,
  pageNum,
});

export const getUsersSuccess = (user) => ({
  type: adminActionTypes.GET_USERS_SUCCESS,
  payload: user,
});
export const setPagination = (pagination) => ({
  type: adminActionTypes.SET_PAGINATION,
  payload: pagination,
});
export const setPaginationBuy = (pagination) => ({
  type: adminActionTypes.SET_PAGINATION_BUY,
  payload: pagination,
});

export const getUsersFailure = (error) => ({
  type: adminActionTypes.GET_USERS_FAILURE,
  payload: error,
});
export const getTransactionsStart = () => ({
  type: adminActionTypes.GET_TRANSACTIONS_START,
});

export const getTransactionsSuccess = (user) => ({
  type: adminActionTypes.GET_TRANSACTIONS_SUCCESS,
  payload: user,
});

export const getTransactionsFailure = (error) => ({
  type: adminActionTypes.GET_TRANSACTIONS_FAILURE,
  payload: error,
});

export const getPendingPaymentStart = () => ({
  type: adminActionTypes.GET_PENDING_PAYMENT_START,
});

export const getPendingPaymentSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_PAYMENT_SUCCESS,
  payload: user,
});

export const getPendingPaymentFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_PAYMENT_FAILURE,
  payload: error,
});

export const getApprovedPaymentStart = () => ({
  type: adminActionTypes.GET_APPROVED_PAYMENT_START,
});

export const getApprovedPaymentSuccess = (user) => ({
  type: adminActionTypes.GET_APPROVED_PAYMENT_SUCCESS,
  payload: user,
});

export const getApprovedPaymentFailure = (error) => ({
  type: adminActionTypes.GET_APPROVED_PAYMENT_FAILURE,
  payload: error,
});

export const getRejectedPaymentStart = () => ({
  type: adminActionTypes.GET_REJECTED_PAYMENT_START,
});

export const getRejectedPaymentSuccess = (user) => ({
  type: adminActionTypes.GET_REJECTED_PAYMENT_SUCCESS,
  payload: user,
});

export const getRejectedPaymentFailure = (error) => ({
  type: adminActionTypes.GET_REJECTED_PAYMENT_FAILURE,
  payload: error,
});

export const getTradesStart = () => ({
  type: adminActionTypes.GET_TRADES_START,
});

export const getTradesSuccess = (user) => ({
  type: adminActionTypes.GET_TRADES_SUCCESS,
  payload: user,
});

export const getTradesFailure = (error) => ({
  type: adminActionTypes.GET_TRADES_FAILURE,
  payload: error,
});

export const getCompletedTradesStart = () => ({
  type: adminActionTypes.GET_COMPLETED_TRADES_START,
});

export const getCompletedTradesSuccess = (user) => ({
  type: adminActionTypes.GET_COMPLETED_TRADES_SUCCESS,
  payload: user,
});

export const getCompletedTradesFailure = (error) => ({
  type: adminActionTypes.GET_COMPLETED_TRADES_FAILURE,
  payload: error,
});

export const getPendingTradesStart = () => ({
  type: adminActionTypes.GET_PENDING_TRADES_START,
});

export const getPendingTradesSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_TRADES_SUCCESS,
  payload: user,
});

export const getPendingTradesFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_TRADES_FAILURE,
  payload: error,
});

export const getRejectedTradesStart = () => ({
  type: adminActionTypes.GET_REJECTED_TRADES_START,
});

export const getRejectedTradesSuccess = (user) => ({
  type: adminActionTypes.GET_REJECTED_TRADES_SUCCESS,
  payload: user,
});

export const getRejectedTradesFailure = (error) => ({
  type: adminActionTypes.GET_REJECTED_TRADES_FAILURE,
  payload: error,
});

export const getWithdrawalRequestsStart = () => ({
  type: adminActionTypes.GET_WITHDRAWAL_REQUESTS_START,
});

export const getWithdrawalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getWithdrawalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_WITHDRAWAL_REQUESTS_FAILURE,
  payload: error,
});

export const getApprovedWithdrawalRequestsStart = () => ({
  type: adminActionTypes.GET_APPROVED_WITHDRAWAL_REQUESTS_START,
});

export const getApprovedWithdrawalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_APPROVED_WITHDRAWAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getApprovedWithdrawalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_APPROVED_WITHDRAWAL_REQUESTS_FAILURE,
  payload: error,
});

export const getPendingWithdrawalRequestsStart = () => ({
  type: adminActionTypes.GET_PENDING_WITHDRAWAL_REQUESTS_START,
});

export const getPendingWithdrawalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_WITHDRAWAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getPendingWithdrawalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_WITHDRAWAL_REQUESTS_FAILURE,
  payload: error,
});

export const getDeclinedWithdrawalRequestsStart = () => ({
  type: adminActionTypes.GET_DECLINED_WITHDRAWAL_REQUESTS_START,
});

export const getDeclinedWithdrawalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_DECLINED_WITHDRAWAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getDeclinedWithdrawalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_DECLINED_WITHDRAWAL_REQUESTS_FAILURE,
  payload: error,
});

export const getReferralsStart = () => ({
  type: adminActionTypes.GET_REFERRALS_START,
});

export const getReferralsSuccess = (user) => ({
  type: adminActionTypes.GET_REFERRALS_SUCCESS,
  payload: user,
});

export const getReferralsFailure = (error) => ({
  type: adminActionTypes.GET_REFERRALS_FAILURE,
  payload: error,
});

export const getWalletSwapsStart = () => ({
  type: adminActionTypes.GET_WALLET_SWAPS_START,
});

export const getWalletSwapsSuccess = (user) => ({
  type: adminActionTypes.GET_WALLET_SWAPS_SUCCESS,
  payload: user,
});

export const getWalletSwapsFailure = (error) => ({
  type: adminActionTypes.GET_WALLET_SWAPS_FAILURE,
  payload: error,
});

// paypal
export const getPaypalRequestsStart = () => ({
  type: adminActionTypes.GET_PAYPAL_REQUESTS_START,
});

export const getPaypalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_PAYPAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getPaypalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_PAYPAL_REQUESTS_FAILURE,
  payload: error,
});

export const getApprovedPaypalRequestsStart = () => ({
  type: adminActionTypes.GET_APPROVED_PAYPAL_REQUESTS_START,
});

export const getApprovedPaypalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_APPROVED_PAYPAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getApprovedPaypalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_APPROVED_PAYPAL_REQUESTS_FAILURE,
  payload: error,
});

export const getPendingPaypalRequestsStart = () => ({
  type: adminActionTypes.GET_PENDING_PAYPAL_REQUESTS_START,
});

export const getPendingPaypalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_PAYPAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getPendingPaypalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_PAYPAL_REQUESTS_FAILURE,
  payload: error,
});

export const getDeclinedPaypalRequestsStart = () => ({
  type: adminActionTypes.GET_DECLINED_PAYPAL_REQUESTS_START,
});

export const getDeclinedPaypalRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_DECLINED_PAYPAL_REQUESTS_SUCCESS,
  payload: user,
});

export const getDeclinedPaypalRequestsFailure = (error) => ({
  type: adminActionTypes.GET_DECLINED_PAYPAL_REQUESTS_FAILURE,
  payload: error,
});

// cashapp
export const getCashAppRequestsStart = () => ({
  type: adminActionTypes.GET_CASHAPP_REQUESTS_START,
});

export const getCashAppRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_CASHAPP_REQUESTS_SUCCESS,
  payload: user,
});

export const getCashAppRequestsFailure = (error) => ({
  type: adminActionTypes.GET_CASHAPP_REQUESTS_FAILURE,
  payload: error,
});

export const getApprovedCashAppRequestsStart = () => ({
  type: adminActionTypes.GET_APPROVED_CASHAPP_REQUESTS_START,
});

export const getApprovedCashAppRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_APPROVED_CASHAPP_REQUESTS_SUCCESS,
  payload: user,
});

export const getApprovedCashAppRequestsFailure = (error) => ({
  type: adminActionTypes.GET_APPROVED_CASHAPP_REQUESTS_FAILURE,
  payload: error,
});

export const getPendingCashAppRequestsStart = () => ({
  type: adminActionTypes.GET_PENDING_CASHAPP_REQUESTS_START,
});

export const getPendingCashAppRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_CASHAPP_REQUESTS_SUCCESS,
  payload: user,
});

export const getPendingCashAppRequestsFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_CASHAPP_REQUESTS_FAILURE,
  payload: error,
});

export const getDeclinedCashAppRequestsStart = () => ({
  type: adminActionTypes.GET_DECLINED_CASHAPP_REQUESTS_START,
});

export const getDeclinedCashAppRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_DECLINED_CASHAPP_REQUESTS_SUCCESS,
  payload: user,
});

export const getDeclinedCashAppRequestsFailure = (error) => ({
  type: adminActionTypes.GET_DECLINED_CASHAPP_REQUESTS_FAILURE,
  payload: error,
});

// usdt
export const getUsdtRequestsStart = () => ({
  type: adminActionTypes.GET_USDT_REQUESTS_START,
});

export const getUsdtRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_USDT_REQUESTS_SUCCESS,
  payload: user,
});

export const getUsdtRequestsFailure = (error) => ({
  type: adminActionTypes.GET_USDT_REQUESTS_FAILURE,
  payload: error,
});

export const getApprovedUsdtRequestsStart = () => ({
  type: adminActionTypes.GET_APPROVED_USDT_REQUESTS_START,
});

export const getApprovedUsdtRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_APPROVED_USDT_REQUESTS_SUCCESS,
  payload: user,
});

export const getApprovedUsdtRequestsFailure = (error) => ({
  type: adminActionTypes.GET_APPROVED_USDT_REQUESTS_FAILURE,
  payload: error,
});

export const getPendingUsdtRequestsStart = () => ({
  type: adminActionTypes.GET_PENDING_USDT_REQUESTS_START,
});

export const getPendingUsdtRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_USDT_REQUESTS_SUCCESS,
  payload: user,
});

export const getPendingUsdtRequestsFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_USDT_REQUESTS_FAILURE,
  payload: error,
});

export const getDeclinedUsdtRequestsStart = () => ({
  type: adminActionTypes.GET_DECLINED_USDT_REQUESTS_START,
});

export const getDeclinedUsdtRequestsSuccess = (user) => ({
  type: adminActionTypes.GET_DECLINED_USDT_REQUESTS_SUCCESS,
  payload: user,
});

export const getDeclinedUsdtRequestsFailure = (error) => ({
  type: adminActionTypes.GET_DECLINED_USDT_REQUESTS_FAILURE,
  payload: error,
});

export const getPendingCryptoTransactionsStart = () => ({
  type: adminActionTypes.GET_PENDING_CRYPTO_TRANSACTIONS_START,
});

export const getPendingCryptoTransactionsSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_CRYPTO_TRANSACTIONS_SUCCESS,
  payload: user,
});

export const getPendingCryptoTransactionsFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_CRYPTO_TRANSACTIONS_FAILURE,
  payload: error,
});

export const getProcessedCryptoTransactionsStart = () => ({
  type: adminActionTypes.GET_PROCESSED_CRYPTO_TRANSACTIONS_START,
});

export const getProcessedCryptoTransactionsSuccess = (user) => ({
  type: adminActionTypes.GET_PROCESSED_CRYPTO_TRANSACTIONS_SUCCESS,
  payload: user,
});

export const getProcessedCryptoTransactionsFailure = (error) => ({
  type: adminActionTypes.GET_PROCESSED_CRYPTO_TRANSACTIONS_FAILURE,
  payload: error,
});

export const getCryptoTransactionsStart = () => ({
  type: adminActionTypes.GET_CRYPTO_TRANSACTIONS_START,
});

export const getCryptoTransactionsSuccess = (user) => ({
  type: adminActionTypes.GET_CRYPTO_TRANSACTIONS_SUCCESS,
  payload: user,
});

export const getCryptoTransactionsFailure = (error) => ({
  type: adminActionTypes.GET_CRYPTO_TRANSACTIONS_FAILURE,
  payload: error,
});

export const getPendingCryptoProofsStart = () => ({
  type: adminActionTypes.GET_PENDING_CRYPTO_PROOFS_START,
});

export const getPendingCryptoProofsSuccess = (user) => ({
  type: adminActionTypes.GET_PENDING_CRYPTO_PROOFS_SUCCESS,
  payload: user,
});

export const getPendingCryptoProofsFailure = (error) => ({
  type: adminActionTypes.GET_PENDING_CRYPTO_PROOFS_FAILURE,
  payload: error,
});

export const getProcessedCryptoProofsStart = () => ({
  type: adminActionTypes.GET_PROCESSED_CRYPTO_PROOFS_START,
});

export const getProcessedCryptoProofsSuccess = (user) => ({
  type: adminActionTypes.GET_PROCESSED_CRYPTO_PROOFS_SUCCESS,
  payload: user,
});

export const getProcessedCryptoProofsFailure = (error) => ({
  type: adminActionTypes.GET_PROCESSED_CRYPTO_PROOFS_FAILURE,
  payload: error,
});

export const getDeclinedCryptoProofsStart = () => ({
  type: adminActionTypes.GET_DECLINED_CRYPTO_PROOFS_START,
});

export const getDeclinedCryptoProofsSuccess = (user) => ({
  type: adminActionTypes.GET_DECLINED_CRYPTO_PROOFS_SUCCESS,
  payload: user,
});

export const getDeclinedCryptoProofsFailure = (error) => ({
  type: adminActionTypes.GET_DECLINED_CRYPTO_PROOFS_FAILURE,
  payload: error,
});

export const getCryptoProofsStart = () => ({
  type: adminActionTypes.GET_CRYPTO_PROOFS_START,
});

export const getCryptoProofsSuccess = (user) => ({
  type: adminActionTypes.GET_CRYPTO_PROOFS_SUCCESS,
  payload: user,
});

export const getCryptoProofsFailure = (error) => ({
  type: adminActionTypes.GET_CRYPTO_PROOFS_FAILURE,
  payload: error,
});

export const getCryptoSellRateStart = () => ({
  type: adminActionTypes.GET_CRYPTO_SELL_RATE_START,
});

export const getCryptoSellRateSuccess = (user) => ({
  type: adminActionTypes.GET_CRYPTO_SELL_RATE_SUCCESS,
  payload: user,
});

export const getCryptoSellRateFailure = (error) => ({
  type: adminActionTypes.GET_CRYPTO_SELL_RATE_FAILURE,
  payload: error,
});

export const getCryptoRatesStart = () => ({
  type: adminActionTypes.GET_CRYPTO_RATES_START,
});

export const getCryptoRatesSuccess = (user) => ({
  type: adminActionTypes.GET_CRYPTO_RATES_SUCCESS,
  payload: user,
});

export const getCryptoRatesFailure = (error) => ({
  type: adminActionTypes.GET_CRYPTO_RATES_FAILURE,
  payload: error,
});

export const getCurrencyRatesStart = () => ({
  type: adminActionTypes.GET_CURRENCY_RATES_START,
});

export const getCurrencyRatesSuccess = (user) => ({
  type: adminActionTypes.GET_CURRENCY_RATES_SUCCESS,
  payload: user,
});

export const getCurrencyRatesFailure = (error) => ({
  type: adminActionTypes.GET_CURRENCY_RATES_FAILURE,
  payload: error,
});

export const getSubCategoryStart = () => ({
  type: adminActionTypes.GET_SUBCATEGORY_START,
});

export const getSubCategorySuccess = (user) => ({
  type: adminActionTypes.GET_SUBCATEGORY_SUCCESS,
  payload: user,
});

export const getSubCategoryFailure = (error) => ({
  type: adminActionTypes.GET_SUBCATEGORY_FAILURE,
  payload: error,
});
export const getCategoryStart = () => ({
  type: adminActionTypes.GET_CATEGORY_START,
});

export const getCategorySuccess = (user) => ({
  type: adminActionTypes.GET_CATEGORY_SUCCESS,
  payload: user,
});

export const getCategoryFailure = (error) => ({
  type: adminActionTypes.GET_CATEGORY_FAILURE,
  payload: error,
});
export const getUsersBalanceStart = () => ({
  type: adminActionTypes.GET_USER_BALANCE_START,
});

export const getUsersBalanceSuccess = (user) => ({
  type: adminActionTypes.GET_USER_BALANCE_SUCCESS,
  payload: user,
});

export const getUsersBalanceFailure = (error) => ({
  type: adminActionTypes.GET_SUBCATEGORY_FAILURE,
  payload: error,
});
export const editUserStart = () => ({
  type: adminActionTypes.EDIT_USERS_START,
});

export const editUserSuccess = (user) => ({
  type: adminActionTypes.EDIT_USERS_SUCCESS,
  payload: user,
});

export const editUserFailure = (error) => ({
  type: adminActionTypes.EDIT_USERS_SUCCESS,
  payload: error,
});
export const deleteUserStart = () => ({
  type: adminActionTypes.DELETE_USERS_START,
});

export const deleteUserSuccess = (user) => ({
  type: adminActionTypes.DELETE_USERS_SUCCESS,
  payload: user,
});

export const deleteUserFailure = (error) => ({
  type: adminActionTypes.DELETE_USERS_FAILURE,
  payload: error,
});
export const banUserStart = () => ({
  type: adminActionTypes.BAN_USERS_START,
});

export const banUserSuccess = (user) => ({
  type: adminActionTypes.BAN_USERS_SUCCESS,
  payload: user,
});

export const banUserFailure = (error) => ({
  type: adminActionTypes.BAN_USERS_FAILURE,
  payload: error,
});
export const editRateStart = () => ({
  type: adminActionTypes.EDIT_RATES_START,
});

export const editRateSuccess = (user) => ({
  type: adminActionTypes.EDIT_RATES_SUCCESS,
  payload: user,
});

export const editRateFailure = (error) => ({
  type: adminActionTypes.EDIT_RATES_FAILURE,
  payload: error,
});

export const getSettingsStart = () => ({
  type: adminActionTypes.SETTINGS_START,
});

export const getSettingsSuccess = (user) => ({
  type: adminActionTypes.SETTINGS_SUCCESS,
  payload: user,
});

export const getSettingsFailure = (error) => ({
  type: adminActionTypes.SETTINGS_FAILURE,
  payload: error,
});

export const getReasonsStart = () => ({
  type: adminActionTypes.GET_REASONS_START,
});

export const getReasonsSuccess = (user) => ({
  type: adminActionTypes.GET_REASONS_SUCCESS,
  payload: user,
});

export const getReasonsFailure = (error) => ({
  type: adminActionTypes.GET_REASONS_FAILURE,
  payload: error,
});

export const getGiftcodesStart = () => ({
  type: adminActionTypes.GET_GIFTCODES_START,
});

export const getGiftcodesSuccess = (giftcodes) => ({
  type: adminActionTypes.GET_GIFTCODES_SUCCESS,
  payload: giftcodes,
});

export const getGiftcodesFailure = (error) => ({
  type: adminActionTypes.GET_GIFTCODES_FAILURE,
  payload: error,
});

export const getTopupStart = () => ({
  type: adminActionTypes.GET_TOPUP_START,
});

export const getTopupSuccess = (topup) => ({
  type: adminActionTypes.GET_TOPUP_SUCCESS,
  payload: topup,
});

export const getTopupFailure = (error) => ({
  type: adminActionTypes.GET_TOPUP_FAILURE,
  payload: error,
});
export const getRefillStart = () => ({
  type: adminActionTypes.GET_REFILL_START,
});

export const getRefillSuccess = (topup) => ({
  type: adminActionTypes.GET_REFILL_SUCCESS,
  payload: topup,
});

export const getRefillFailure = (error) => ({
  type: adminActionTypes.GET_REFILL_FAILURE,
});
export const getBuyTradesStart = () => ({
  type: adminActionTypes.GET_BUY_TRADES_START,
});

export const getBuyTradesSuccess = (user) => ({
  type: adminActionTypes.GET_BUY_TRADES_SUCCESS,
  payload: user,
});

export const getBuyTradesFailure = (error) => ({
  type: adminActionTypes.GET_BUY_TRADES_FAILURE,

  payload: error,
});
export const getGiftcardStart = () => ({
  type: adminActionTypes.GET_GIFTCARD_START,
});

export const getGiftcardSuccess = (user) => ({
  type: adminActionTypes.GET_GIFTCARD_SUCCESS,
  payload: user,
});

export const getGiftcardFailure = (error) => ({
  type: adminActionTypes.GET_GIFTCARD_FAILURE,
  payload: error,
});

export const getPromoCodesStart = () => ({
  type: adminActionTypes.GET_PROMO_CODE_START,
});

export const getPromoCodesSuccess = (user) => ({
  type: adminActionTypes.GET_PROMO_CODE_SUCCESS,
  payload: user,
});

export const getPromoCodesFailure = (error) => ({
  type: adminActionTypes.GET_PROMO_CODE_FAILURE,
  payload: error,
});

export const getBannedUsersStart = () => ({
  type: adminActionTypes.GET_BANNED_USERS_START,
});

export const getBannedUsersSuccess = (user) => ({
  type: adminActionTypes.GET_BANNED_USERS_SUCCESS,
  payload: user,
});

export const getBannedUsersFailure = (error) => ({
  type: adminActionTypes.GET_BANNED_USERS_FAILURE,
  payload: error,
});

export const getAllCoinsStart = () => ({
  type: adminActionTypes.GET_ALL_COINS_START,
});

export const getAllCoinsSuccess = (user) => ({
  type: adminActionTypes.GET_ALL_COINS_SUCCESS,
  payload: user,
});

export const getAllCoinsFailure = (error) => ({
  type: adminActionTypes.GET_ALL_COINS_FAILURE,
  payload: error,
});
