import adminActionTypes from "../types/admin.type";
const INITIAL_STATE = {
  users: [],
  error: null,
  success: null,
  isLoading: false,
  getUserError: null,
  getUserSuccess: null,
  transactions: [],

  paymentLoading: false,
  pendingPayments: [],
  pendingPaymentLoading: false,
  pendingPaymentSuccess: null,
  pendingPaymentError: null,

  rejectedPayments: [],
  rejectedPaymentLoading: false,
  rejectedPaymentSuccess: null,
  rejectedPaymentError: null,

  approvedPayments: [],
  approvedPaymentLoading: false,
  approvedPaymentSuccess: null,
  approvedPaymentError: null,

  trades: [],
  rates: [],
  balance: [],
  // giftCards: [],

  settings: [],
  settingsLoading: false,
  settingsSuccess: null,
  settingsFailure: null,

  subCategories: [],
  subCategoryError: null,
  subCategorySuccess: null,
  subCategoryLoading: false,

  balanceLoading: false,

  categories: [],
  categoryLoading: false,
  categoryError: null,
  categorySuccess: null,

  tradeLoading: false,
  tradeError: null,
  tradeSuccess: null,
  tradeBuyLoading: false,
  tradeBuyError: null,
  tradeBuySuccess: null,
  tradesBuy: [],

  cryptoTransactions: [],
  cryptoTransactionsLoading: false,
  cryptoTransactionsSuccess: null,
  cryptoTransactionsError: null,

  cryptoSellRate: [],
  cryptoSellRateLoading: false,
  cryptoSellRateSuccess: null,
  cryptoSellRateError: null,

  cryptoRates: [],
  cryptoRatesLoading: false,
  cryptoRatesSuccess: null,
  cryptoRatesError: null,
  // editCryptoRatesLoading: false,
  // editCryptoRatesSuccess: null,
  // editCryptoRatesError: null,

  currencyRates: [],
  currencyRatesLoading: false,
  currencyRatesSuccess: null,
  currencyRatesError: null,

  completedTrade: [],
  completedTradeLoading: false,
  completedTradeSuccess: null,
  completedTradeError: null,

  pendingTrade: [],
  pendingTradeLoading: false,
  pendingTradeSuccess: null,
  pendingTradeError: null,

  rejectedTrade: [],
  rejectedTradeLoading: false,
  rejectedTradeSuccess: null,
  rejectedTradeError: null,

  withdrawalRequests: [],
  withdrawalRequestsLoading: false,
  withdrawalRequestsSuccess: null,
  withdrawalRequestsError: null,

  approvedWithdrawalRequests: [],
  approvedWithdrawalRequestsLoading: false,
  approvedWithdrawalRequestsSuccess: null,
  approvedWithdrawalRequestsError: null,

  pendingWithdrawalRequests: [],
  pendingWithdrawalRequestsLoading: false,
  pendingWithdrawalRequestsSuccess: null,
  pendingWithdrawalRequestsError: null,

  declinedWithdrawalRequests: [],
  declinedWithdrawalRequestsLoading: false,
  declinedWithdrawalRequestsSuccess: null,
  declinedWithdrawalRequestsError: null,

  // paypal
  paypalRequests: [],
  paypalRequestsLoading: false,
  paypalRequestsSuccess: null,
  paypalRequestsError: null,

  approvedPaypalRequests: [],
  approvedPaypalRequestsLoading: false,
  approvedPaypalRequestsSuccess: null,
  approvedPaypalRequestsError: null,

  pendingPaypalRequests: [],
  pendingPaypalRequestsLoading: false,
  pendingPaypalRequestsSuccess: null,
  pendingPaypalRequestsError: null,

  declinedPaypalRequests: [],
  declinedPaypalRequestsLoading: false,
  declinedPaypalRequestsSuccess: null,
  declinedPaypalRequestsError: null,

  referrals: [],
  referralsLoading: false,
  referralsSuccess: null,
  referralsError: null,

  walletSwaps: [],
  walletSwapsLoading: false,
  walletSwapsSuccess: null,
  walletSwapsError: null,

  // cash app
  cashAppRequests: [],
  cashAppRequestsLoading: false,
  cashAppRequestsSuccess: null,
  cashAppRequestsError: null,

  approvedCashAppRequests: [],
  approvedCashAppRequestsLoading: false,
  approvedCashAppRequestsSuccess: null,
  approvedCashAppRequestsError: null,

  pendingCashAppRequests: [],
  pendingCashAppRequestsLoading: false,
  pendingCashAppRequestsSuccess: null,
  pendingCashAppRequestsError: null,

  declinedCashAppRequests: [],
  declinedCashAppRequestsLoading: false,
  declinedCashAppRequestsSuccess: null,
  declinedCashAppRequestsError: null,

  // usdt
  usdtRequests: [],
  usdtRequestsLoading: false,
  usdtRequestsSuccess: null,
  usdtRequestsError: null,

  approvedUsdtRequests: [],
  approvedUsdtRequestsLoading: false,
  approvedUsdtRequestsSuccess: null,
  approvedUsdtRequestsError: null,

  pendingUsdtRequests: [],
  pendingUsdtRequestsLoading: false,
  pendingUsdtRequestsSuccess: null,
  pendingUsdtRequestsError: null,

  declinedUsdtRequests: [],
  declinedUsdtRequestsLoading: false,
  declinedUsdtRequestsSuccess: null,
  declinedUsdtRequestsError: null,

  transactionLoading: false,
  transactionError: null,
  transactionSuccess: null,

  editUserError: null,
  editUserSuccess: null,
  editUserLoading: false,

  banUserLoading: false,
  banUserError: null,
  banUserSuccess: null,

  deleteUserLoading: false,
  deleteUserError: null,
  deleteUserSuccess: null,

  editRateLoading: false,
  editRateError: null,
  editRateSuccess: null,

  getReasonsLoading: false,
  getReasonsError: null,
  getReasonsSuccess: null,

  pagination: {},
  paginationBuy: {},

  reasons: [],

  giftcodes: [],
  getGiftcodesSuccess: null,
  getGiftcodesLoading: false,
  getGiftcodesError: null,

  promoCodes: [],
  promoCodesSuccess: null,
  promoCodesLoading: false,
  promoCodesError: null,

  bannedUsers: [],
  bannedUsersSuccess: null,
  bannedUsersLoading: false,
  bannedUsersError: null,

  topup: [],
  getTopupSuccess: null,
  getTopupLoading: false,
  getTopupError: null,

  refill: [],
  getRefillSuccess: null,
  getRefillLoading: false,
  getRefillError: null,

  allCoins: [],
  allCoinsSuccess: null,
  allCoinsLoading: false,
  allCoinsError: null,

  // giftCardLoading: false,
  // giftCardError: null,
  // giftCardSuccess: null,
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case adminActionTypes.GET_GIFTCARD_START:
    //   return {
    //     ...state,
    //     giftCardLoading: true,
    //     giftCardError: null,
    //     giftCardSuccess: null,
    //   };
    // case adminActionTypes.GET_GIFTCARD_SUCCESS:
    //   return {
    //     ...state,
    //     giftCards: action.payload,
    //     giftCardLoading: false,
    //     giftCardError: null,
    //     giftCardSuccess: action.payload,
    //   };
    // case adminActionTypes.GET_GIFTCARD_FAILURE:
    //   return {
    //     ...state,
    //     giftCardLoading: false,
    //     giftCardError: action.payload,
    //     giftCardSuccess: null,
    //     giftCards: [],
    //   };
    case adminActionTypes.GET_PROMO_CODE_START:
      return {
        ...state,
        promoCodesLoading: true,
        promoCodesError: null,
        promoCodesSuccess: null,
      };
    case adminActionTypes.GET_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodes: action.payload,
        promoCodesLoading: false,
        promoCodesError: null,
        promoCodesSuccess: action.payload,
      };
    case adminActionTypes.GET_PROMO_CODE_FAILURE:
      return {
        ...state,
        promoCodesLoading: false,
        promoCodesError: action.payload,
        promoCodesSuccess: null,
        promoCodes: [],
      };
    case adminActionTypes.GET_BANNED_USERS_START:
      return {
        ...state,
        bannedUsersLoading: true,
        bannedUsersError: null,
        bannedUsersSuccess: null,
      };
    case adminActionTypes.GET_BANNED_USERS_SUCCESS:
      return {
        ...state,
        bannedUsers: action.payload,
        bannedUsersLoading: false,
        bannedUsersError: null,
        bannedUsersSuccess: action.payload,
      };
    case adminActionTypes.GET_BANNED_USERS_FAILURE:
      return {
        ...state,
        bannedUsersLoading: false,
        bannedUsersError: action.payload,
        bannedUsersSuccess: null,
        bannedUsers: [],
      };
    case adminActionTypes.GET_REFILL_START:
      return {
        ...state,
        getRefillLoading: true,
        getRefillError: null,
        getRefillSuccess: null,
      };
    case adminActionTypes.GET_REFILL_SUCCESS:
      return {
        ...state,
        getRefillLoading: false,
        getRefillError: null,
        getRefillSuccess: action.payload,
        refill: action.payload,
      };
    case adminActionTypes.GET_REFILL_FAILURE:
      return {
        ...state,
        getRefillLoading: false,
        getRefillError: action.payload,
        getRefillSuccess: null,
      };
    case adminActionTypes.GET_APPROVED_WITHDRAWAL_REQUESTS_START:
      return {
        ...state,
        approvedWithdrawalRequestsLoading: true,
        approvedWithdrawalRequestsError: null,
        approvedWithdrawalRequestsSuccess: null,
      };
    case adminActionTypes.GET_APPROVED_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        approvedWithdrawalRequestsLoading: false,
        approvedWithdrawalRequestsSuccess: action.payload,
        approvedWithdrawalRequests: action.payload,
        approvedWithdrawalRequestsError: null,
      };
    case adminActionTypes.GET_APPROVED_WITHDRAWAL_REQUESTS_FAILURE:
      return {
        ...state,
        approvedWithdrawalRequestsLoading: false,
        approvedWithdrawalRequestsSuccess: null,
        approvedWithdrawalRequestsError: action.payload,
      };

    case adminActionTypes.GET_PENDING_WITHDRAWAL_REQUESTS_START:
      return {
        ...state,
        pendingWithdrawalRequestsLoading: true,
        pendingWithdrawalRequestsError: null,
        pendingWithdrawalRequestsSuccess: null,
      };
    case adminActionTypes.GET_PENDING_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingWithdrawalRequestsLoading: false,
        pendingWithdrawalRequestsSuccess: action.payload,
        pendingWithdrawalRequests: action.payload,
        pendingWithdrawalRequestsError: null,
      };
    case adminActionTypes.GET_PENDING_WITHDRAWAL_REQUESTS_FAILURE:
      return {
        ...state,
        pendingWithdrawalRequestsLoading: false,
        pendingWithdrawalRequestsSuccess: null,
        pendingWithdrawalRequestsError: action.payload,
      };

    case adminActionTypes.GET_DECLINED_WITHDRAWAL_REQUESTS_START:
      return {
        ...state,
        declinedWithdrawalRequestsLoading: true,
        declinedWithdrawalRequestsError: null,
        declinedWithdrawalRequestsSuccess: null,
      };

    case adminActionTypes.GET_DECLINED_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        declinedWithdrawalRequestsLoading: false,
        declinedWithdrawalRequestsSuccess: action.payload,
        declinedWithdrawalRequests: action.payload,
        declinedWithdrawalRequestsError: null,
      };

    case adminActionTypes.GET_DECLINED_WITHDRAWAL_REQUESTS_FAILURE:
      return {
        ...state,
        declinedWithdrawalRequestsLoading: false,
        declinedWithdrawalRequestsSuccess: null,
        declinedWithdrawalRequestsError: action.payload,
      };

    case adminActionTypes.GET_WITHDRAWAL_REQUESTS_START:
      return {
        ...state,
        withdrawalRequestsLoading: true,
        withdrawalRequestsError: null,
        withdrawalRequestsSuccess: null,
      };
    case adminActionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        withdrawalRequestsLoading: false,
        withdrawalRequestsError: null,
        withdrawalRequestsSuccess: action.payload,
        withdrawalRequests: action.payload,
      };
    case adminActionTypes.GET_WITHDRAWAL_REQUESTS_FAILURE:
      return {
        ...state,
        withdrawalRequestsLoading: false,
        withdrawalRequestsSuccess: null,
        withdrawalRequestsError: action.payload,
      };
    // paypal
    case adminActionTypes.GET_APPROVED_PAYPAL_REQUESTS_START:
      return {
        ...state,
        approvedPaypalRequestsLoading: true,
        approvedPaypalRequestsError: null,
        approvedPaypalRequestsSuccess: null,
      };
    case adminActionTypes.GET_APPROVED_PAYPAL_REQUESTS_SUCCESS:
      return {
        ...state,
        approvedPaypalRequestsLoading: false,
        approvedPaypalRequestsSuccess: action.payload,
        approvedPaypalRequests: action.payload,
        approvedPaypalRequestsError: null,
      };
    case adminActionTypes.GET_APPROVED_PAYPAL_REQUESTS_FAILURE:
      return {
        ...state,
        approvedPaypalRequestsLoading: false,
        approvedPaypalRequestsSuccess: null,
        approvedPaypalRequestsError: action.payload,
      };

    case adminActionTypes.GET_PENDING_PAYPAL_REQUESTS_START:
      return {
        ...state,
        pendingPaypalRequestsLoading: true,
        pendingPaypalRequestsError: null,
        pendingPaypalRequestsSuccess: null,
      };
    case adminActionTypes.GET_PENDING_PAYPAL_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingPaypalRequestsLoading: false,
        pendingPaypalRequestsSuccess: action.payload,
        pendingPaypalRequests: action.payload,
        pendingPaypalRequestsError: null,
      };
    case adminActionTypes.GET_PENDING_PAYPAL_REQUESTS_FAILURE:
      return {
        ...state,
        pendingPaypalRequestsLoading: false,
        pendingPaypalRequestsSuccess: null,
        pendingPaypalRequestsError: action.payload,
      };

    case adminActionTypes.GET_DECLINED_PAYPAL_REQUESTS_START:
      return {
        ...state,
        declinedPaypalRequestsLoading: true,
        declinedPaypalRequestsError: null,
        declinedPaypalRequestsSuccess: null,
      };

    case adminActionTypes.GET_DECLINED_PAYPAL_REQUESTS_SUCCESS:
      return {
        ...state,
        declinedPaypalRequestsLoading: false,
        declinedPaypalRequestsSuccess: action.payload,
        declinedPaypalRequests: action.payload,
        declinedPaypalRequestsError: null,
      };

    case adminActionTypes.GET_DECLINED_PAYPAL_REQUESTS_FAILURE:
      return {
        ...state,
        declinedPaypalRequestsLoading: false,
        declinedPaypalRequestsSuccess: null,
        declinedPaypalRequestsError: action.payload,
      };

    case adminActionTypes.GET_PAYPAL_REQUESTS_START:
      return {
        ...state,
        paypalRequestsLoading: true,
        paypalRequestsError: null,
        paypalRequestsSuccess: null,
      };
    case adminActionTypes.GET_PAYPAL_REQUESTS_SUCCESS:
      return {
        ...state,
        paypalRequestsLoading: false,
        paypalRequestsError: null,
        paypalRequestsSuccess: action.payload,
        paypalRequests: action.payload,
      };
    case adminActionTypes.GET_PAYPAL_REQUESTS_FAILURE:
      return {
        ...state,
        paypalRequestsLoading: false,
        paypalRequestsSuccess: null,
        paypalRequestsError: action.payload,
      };
    // cash app
    case adminActionTypes.GET_APPROVED_CASHAPP_REQUESTS_START:
      return {
        ...state,
        approvedCashAppRequestsLoading: true,
        approvedCashAppRequestsError: null,
        approvedCashAppRequestsSuccess: null,
      };
    case adminActionTypes.GET_APPROVED_CASHAPP_REQUESTS_SUCCESS:
      return {
        ...state,
        approvedCashAppRequestsLoading: false,
        approvedCashAppRequestsSuccess: action.payload,
        approvedCashAppRequests: action.payload,
        approvedCashAppRequestsError: null,
      };
    case adminActionTypes.GET_APPROVED_CASHAPP_REQUESTS_FAILURE:
      return {
        ...state,
        approvedCashAppRequestsLoading: false,
        approvedCashAppRequestsSuccess: null,
        approvedCashAppRequestsError: action.payload,
      };

    case adminActionTypes.GET_PENDING_CASHAPP_REQUESTS_START:
      return {
        ...state,
        pendingCashAppRequestsLoading: true,
        pendingCashAppRequestsError: null,
        pendingCashAppRequestsSuccess: null,
      };
    case adminActionTypes.GET_PENDING_CASHAPP_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingCashAppRequestsLoading: false,
        pendingCashAppRequestsSuccess: action.payload,
        pendingCashAppRequests: action.payload,
        pendingCashAppRequestsError: null,
      };
    case adminActionTypes.GET_PENDING_CASHAPP_REQUESTS_FAILURE:
      return {
        ...state,
        pendingCashAppRequestsLoading: false,
        pendingCashAppRequestsSuccess: null,
        pendingCashAppRequestsError: action.payload,
      };

    case adminActionTypes.GET_DECLINED_CASHAPP_REQUESTS_START:
      return {
        ...state,
        declinedCashAppRequestsLoading: true,
        declinedCashAppRequestsError: null,
        declinedCashAppRequestsSuccess: null,
      };

    case adminActionTypes.GET_DECLINED_CASHAPP_REQUESTS_SUCCESS:
      return {
        ...state,
        declinedCashAppRequestsLoading: false,
        declinedCashAppRequestsSuccess: action.payload,
        declinedCashAppRequests: action.payload,
        declinedCashAppRequestsError: null,
      };

    case adminActionTypes.GET_DECLINED_CASHAPP_REQUESTS_FAILURE:
      return {
        ...state,
        declinedCashAppRequestsLoading: false,
        declinedCashAppRequestsSuccess: null,
        declinedCashAppRequestsError: action.payload,
      };

    case adminActionTypes.GET_CASHAPP_REQUESTS_START:
      return {
        ...state,
        cashAppRequestsLoading: true,
        cashAppRequestsError: null,
        cashAppRequestsSuccess: null,
      };
    case adminActionTypes.GET_CASHAPP_REQUESTS_SUCCESS:
      return {
        ...state,
        cashAppRequestsLoading: false,
        cashAppRequestsError: null,
        cashAppRequestsSuccess: action.payload,
        cashAppRequests: action.payload,
      };
    case adminActionTypes.GET_CASHAPP_REQUESTS_FAILURE:
      return {
        ...state,
        cashAppRequestsLoading: false,
        cashAppRequestsSuccess: null,
        cashAppRequestsError: action.payload,
      };

    // usdt
    case adminActionTypes.GET_APPROVED_USDT_REQUESTS_START:
      return {
        ...state,
        approvedUsdtRequestsLoading: true,
        approvedUsdtRequestsError: null,
        approvedUsdtRequestsSuccess: null,
      };
    case adminActionTypes.GET_APPROVED_USDT_REQUESTS_SUCCESS:
      return {
        ...state,
        approvedUsdtRequestsLoading: false,
        approvedUsdtRequestsSuccess: action.payload,
        approvedUsdtRequests: action.payload,
        approvedUsdtRequestsError: null,
      };
    case adminActionTypes.GET_APPROVED_USDT_REQUESTS_FAILURE:
      return {
        ...state,
        approvedUsdtRequestsLoading: false,
        approvedUsdtRequestsSuccess: null,
        approvedUsdtRequestsError: action.payload,
      };

    case adminActionTypes.GET_PENDING_USDT_REQUESTS_START:
      return {
        ...state,
        pendingUsdtRequestsLoading: true,
        pendingUsdtRequestsError: null,
        pendingUsdtRequestsSuccess: null,
      };
    case adminActionTypes.GET_PENDING_USDT_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingUsdtRequestsLoading: false,
        pendingUsdtRequestsSuccess: action.payload,
        pendingUsdtRequests: action.payload,
        pendingUsdtRequestsError: null,
      };
    case adminActionTypes.GET_PENDING_USDT_REQUESTS_FAILURE:
      return {
        ...state,
        pendingUsdtRequestsLoading: false,
        pendingUsdtRequestsSuccess: null,
        pendingUsdtRequestsError: action.payload,
      };

    case adminActionTypes.GET_DECLINED_USDT_REQUESTS_START:
      return {
        ...state,
        declinedUsdtRequestsLoading: true,
        declinedUsdtRequestsError: null,
        declinedUsdtRequestsSuccess: null,
      };

    case adminActionTypes.GET_DECLINED_USDT_REQUESTS_SUCCESS:
      return {
        ...state,
        declinedUsdtRequestsLoading: false,
        declinedUsdtRequestsSuccess: action.payload,
        declinedUsdtRequests: action.payload,
        declinedUsdtRequestsError: null,
      };

    case adminActionTypes.GET_DECLINED_USDT_REQUESTS_FAILURE:
      return {
        ...state,
        declinedUsdtRequestsLoading: false,
        declinedUsdtRequestsSuccess: null,
        declinedUsdtRequestsError: action.payload,
      };

    case adminActionTypes.GET_USDT_REQUESTS_START:
      return {
        ...state,
        usdtRequestsLoading: true,
        usdtRequestsError: null,
        usdtRequestsSuccess: null,
      };
    case adminActionTypes.GET_USDT_REQUESTS_SUCCESS:
      return {
        ...state,
        usdtRequestsLoading: false,
        usdtRequestsError: null,
        usdtRequestsSuccess: action.payload,
        usdtRequests: action.payload,
      };
    case adminActionTypes.GET_USDT_REQUESTS_FAILURE:
      return {
        ...state,
        usdtRequestsLoading: false,
        usdtRequestsSuccess: null,
        usdtRequestsError: action.payload,
      };

    case adminActionTypes.GET_REFERRALS_START:
      return {
        ...state,
        referralsLoading: true,
        referralsError: null,
        referralsSuccess: null,
      };
    case adminActionTypes.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        referralsLoading: false,
        referralsError: null,
        referralsSuccess: action.payload,
        referrals: action.payload,
      };
    case adminActionTypes.GET_REFERRALS_FAILURE:
      return {
        ...state,
        referralsLoading: false,
        referralsSuccess: null,
        referralsError: action.payload,
      };

    case adminActionTypes.GET_WALLET_SWAPS_START:
      return {
        ...state,
        walletSwapsLoading: true,
        walletSwapsError: null,
        walletSwapsSuccess: null,
      };
    case adminActionTypes.GET_WALLET_SWAPS_SUCCESS:
      return {
        ...state,
        walletSwapsLoading: false,
        walletSwapsError: null,
        walletSwapsSuccess: action.payload,
        walletSwaps: action.payload,
      };
    case adminActionTypes.GET_WALLET_SWAPS_FAILURE:
      return {
        ...state,
        walletSwapsLoading: false,
        walletSwapsSuccess: null,
        walletSwapsError: action.payload,
      };
    case adminActionTypes.GET_PENDING_CRYPTO_TRANSACTIONS_START:
      return {
        ...state,
        pendingCryptoTransactionsLoading: true,
        pendingCryptoTransactionsError: null,
        pendingCryptoTransactionsSuccess: null,
      };
    case adminActionTypes.GET_PENDING_CRYPTO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        pendingCryptoTransactionsLoading: false,
        pendingCryptoTransactionsError: null,
        pendingCryptoTransactionsSuccess: action.payload,
        pendingCryptoTransactions: action.payload,
      };
    case adminActionTypes.GET_PENDING_CRYPTO_TRANSACTIONS_FAILURE:
      return {
        ...state,
        pendingCryptoTransactionsLoading: false,
        pendingCryptoTransactionsSuccess: null,
        pendingCryptoTransactionsError: action.payload,
      };
    case adminActionTypes.GET_PROCESSED_CRYPTO_TRANSACTIONS_START:
      return {
        ...state,
        processedCryptoTransactionsLoading: true,
        processedCryptoTransactionsError: null,
        processedCryptoTransactionsSuccess: null,
      };
    case adminActionTypes.GET_PROCESSED_CRYPTO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        processedCryptoTransactionsLoading: false,
        processedCryptoTransactionsError: null,
        processedCryptoTransactionsSuccess: action.payload,
        processedCryptoTransactions: action.payload,
      };
    case adminActionTypes.GET_PROCESSED_CRYPTO_TRANSACTIONS_FAILURE:
      return {
        ...state,
        processedCryptoTransactionsLoading: false,
        processedCryptoTransactionsSuccess: null,
        processedCryptoTransactionsError: action.payload,
      };
    case adminActionTypes.GET_PENDING_CRYPTO_PROOFS_START:
      return {
        ...state,
        pendingCryptoProofsLoading: true,
        pendingCryptoProofsError: null,
        pendingCryptoProofsSuccess: null,
      };
    case adminActionTypes.GET_PENDING_CRYPTO_PROOFS_SUCCESS:
      return {
        ...state,
        pendingCryptoProofsLoading: false,
        pendingCryptoProofsError: null,
        pendingCryptoProofsSuccess: action.payload,
        pendingCryptoProofs: action.payload,
      };
    case adminActionTypes.GET_PENDING_CRYPTO_PROOFS_FAILURE:
      return {
        ...state,
        pendingCryptoProofsLoading: false,
        pendingCryptoProofsSuccess: null,
        pendingCryptoProofsError: action.payload,
      };
    case adminActionTypes.GET_PROCESSED_CRYPTO_PROOFS_FAILURE:
      return {
        ...state,
        processedCryptoProofsLoading: false,
        processedCryptoProofsSuccess: null,
        processedCryptoProofsError: action.payload,
      };
    case adminActionTypes.GET_PROCESSED_CRYPTO_PROOFS_START:
      return {
        ...state,
        processedCryptoProofsLoading: true,
        processedCryptoProofsError: null,
        processedCryptoProofsSuccess: null,
      };
    case adminActionTypes.GET_PROCESSED_CRYPTO_PROOFS_SUCCESS:
      return {
        ...state,
        processedCryptoProofsLoading: false,
        processedCryptoProofsError: null,
        processedCryptoProofsSuccess: action.payload,
        processedCryptoProofs: action.payload,
      };
    case adminActionTypes.GET_CRYPTO_PROOFS_FAILURE:
      return {
        ...state,
        cryptoProofsLoading: false,
        cryptoProofsSuccess: null,
        cryptoProofsError: action.payload,
      };
    case adminActionTypes.GET_CRYPTO_PROOFS_START:
      return {
        ...state,
        cryptoProofsLoading: true,
        cryptoProofsError: null,
        cryptoProofsSuccess: null,
      };
    case adminActionTypes.GET_CRYPTO_PROOFS_SUCCESS:
      return {
        ...state,
        cryptoProofsLoading: false,
        cryptoProofsError: null,
        cryptoProofsSuccess: action.payload,
        cryptoProofs: action.payload,
      };
    case adminActionTypes.GET_DECLINED_CRYPTO_PROOFS_FAILURE:
      return {
        ...state,
        declinedCryptoProofsLoading: false,
        declinedCryptoProofsSuccess: null,
        declinedCryptoProofsError: action.payload,
      };
    case adminActionTypes.GET_DECLINED_CRYPTO_PROOFS_START:
      return {
        ...state,
        declinedCryptoProofsLoading: true,
        declinedCryptoProofsError: null,
        declinedCryptoProofsSuccess: null,
      };
    case adminActionTypes.GET_DECLINED_CRYPTO_PROOFS_SUCCESS:
      return {
        ...state,
        declinedCryptoProofsLoading: false,
        declinedCryptoProofsError: null,
        declinedCryptoProofsSuccess: action.payload,
        declinedCryptoProofs: action.payload,
      };

    case adminActionTypes.GET_COMPLETED_TRADES_START:
      return {
        ...state,
        completedTradeLoading: true,
        completedTradeError: null,
        completedTradeSuccess: null,
      };
    case adminActionTypes.GET_COMPLETED_TRADES_SUCCESS:
      return {
        ...state,
        completedTradeLoading: false,
        completedTradeSuccess: action.payload,
        completedTrade: action.payload,
        completedTradeError: null,
      };
    case adminActionTypes.GET_COMPLETED_TRADES_FAILURE:
      return {
        ...state,
        completedTradeLoading: false,
        completedTradeSuccess: null,
        completedTradeError: action.payload,
      };

    case adminActionTypes.GET_PENDING_TRADES_START:
      return {
        ...state,
        pendingTradeLoading: true,
        pendingTradeError: null,
        pendingTradeSuccess: null,
      };
    case adminActionTypes.GET_PENDING_TRADES_SUCCESS:
      return {
        ...state,
        pendingTradeLoading: false,
        pendingTradeSuccess: action.payload,
        pendingTrade: action.payload,
        pendingTradeError: null,
      };
    case adminActionTypes.GET_PENDING_TRADES_FAILURE:
      return {
        ...state,
        pendingTradeLoading: false,
        pendingTradeSuccess: null,
        pendingTradeError: action.payload,
      };

    case adminActionTypes.GET_REJECTED_TRADES_START:
      return {
        ...state,
        rejectedTradeLoading: true,
        rejectedTradeError: null,
        rejectedTradeSuccess: null,
      };

    case adminActionTypes.GET_REJECTED_TRADES_SUCCESS:
      return {
        ...state,
        rejectedTradeLoading: false,
        rejectedTradeSuccess: action.payload,
        rejectedTrade: action.payload,
        rejectedTradeError: null,
      };

    case adminActionTypes.GET_REJECTED_TRADES_FAILURE:
      return {
        ...state,
        rejectedTradeLoading: false,
        rejectedTradeSuccess: null,
        rejectedTradeError: action.payload,
      };

    case adminActionTypes.GET_BUY_TRADES_START:
      return {
        ...state,
        tradeBuyLoading: true,
        tradeBuyError: null,
        tradeBuySuccess: null,
        paginationBuy: {},
      };
    case adminActionTypes.GET_BUY_TRADES_SUCCESS:
      return {
        ...state,
        tradeBuyLoading: false,
        tradeBuyError: null,
        tradeBuySuccess: action.payload,
        tradesBuy: action.payload,
      };
    case adminActionTypes.GET_BUY_TRADES_FAILURE:
    case adminActionTypes.GET_COMPLETED_TRADES_START:
      return {
        ...state,
        completedTradeLoading: true,
        completedTradeError: null,
        completedTradeSuccess: null,
      };
    case adminActionTypes.GET_COMPLETED_TRADES_SUCCESS:
      return {
        ...state,
        completedTradeLoading: false,
        completedTradeSuccess: action.payload,
        completedTrade: action.payload,
        completedTradeError: null,
      };
    case adminActionTypes.GET_COMPLETED_TRADES_FAILURE:
      return {
        ...state,
        completedTradeLoading: false,
        completedTradeSuccess: null,
        completedTradeError: action.payload,
      };

    case adminActionTypes.GET_PENDING_TRADES_START:
      return {
        ...state,
        pendingTradeLoading: true,
        pendingTradeError: null,
        pendingTradeSuccess: null,
      };
    case adminActionTypes.GET_PENDING_TRADES_SUCCESS:
      return {
        ...state,
        pendingTradeLoading: false,
        pendingTradeSuccess: action.payload,
        pendingTrade: action.payload,
        pendingTradeError: null,
      };
    case adminActionTypes.GET_PENDING_TRADES_FAILURE:
      return {
        ...state,
        pendingTradeLoading: false,
        pendingTradeSuccess: null,
        pendingTradeError: action.payload,
      };

    case adminActionTypes.GET_REJECTED_TRADES_START:
      return {
        ...state,
        rejectedTradeLoading: true,
        rejectedTradeError: null,
        rejectedTradeSuccess: null,
      };

    case adminActionTypes.GET_REJECTED_TRADES_SUCCESS:
      return {
        ...state,
        rejectedTradeLoading: false,
        rejectedTradeSuccess: action.payload,
        rejectedTrade: action.payload,
        rejectedTradeError: null,
      };

    case adminActionTypes.GET_REJECTED_TRADES_FAILURE:
      return {
        ...state,
        rejectedTradeLoading: false,
        rejectedTradeSuccess: null,
        rejectedTradeError: action.payload,
      };

    case adminActionTypes.GET_BUY_TRADES_START:
      return {
        ...state,
        tradeBuyLoading: true,
        tradeBuyError: null,
        tradeBuySuccess: null,
        paginationBuy: {},
      };
    case adminActionTypes.GET_BUY_TRADES_SUCCESS:
      return {
        ...state,
        tradeBuyLoading: false,
        tradeBuyError: null,
        tradeBuySuccess: action.payload,
        tradesBuy: action.payload,
      };
    case adminActionTypes.GET_BUY_TRADES_FAILURE:
      return {
        ...state,
        tradeBuyLoading: false,
        tradeBuyError: action.payload,
        tradeBuySuccess: null,
      };
    case adminActionTypes.GET_TOPUP_START:
      return {
        ...state,
        getTopupLoading: true,
        getTopupError: null,
        getTopupSuccess: null,
      };
    case adminActionTypes.GET_TOPUP_SUCCESS:
      return {
        ...state,
        getTopupLoading: false,
        getTopupError: null,
        getTopupSuccess: action.payload,
        topup: action.payload,
      };
    case adminActionTypes.GET_TOPUP_FAILURE:
      return {
        ...state,
        getTopupLoading: false,
        getTopupError: action.payload,
        getTopupSuccess: null,
      };
    case adminActionTypes.GET_GIFTCODES_START:
      return {
        ...state,
        getGiftcodesLoading: true,
        getGiftcodesError: null,
        getGiftcodesSuccess: null,
      };
    case adminActionTypes.GET_GIFTCODES_SUCCESS:
      return {
        ...state,
        getGiftcodesLoading: false,
        getGiftcodesError: null,
        getGiftcodesSuccess: action.payload,
        giftcodes: action.payload,
      };
    case adminActionTypes.GET_GIFTCODES_FAILURE:
      return {
        ...state,
        getGiftcodesLoading: false,
        getGiftcodesError: action.payload,
        getGiftcodesSuccess: null,
      };
    case adminActionTypes.GET_REASONS_START:
      return {
        ...state,
        getReasonsLoading: true,
        getReasonsError: null,
        getReasonsSuccess: null,
      };
    case adminActionTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        getReasonsLoading: false,
        getReasonsError: null,
        getReasonsSuccess: action.payload,
        reasons: action.payload,
      };
    case adminActionTypes.GET_REASONS_FAILURE:
      return {
        ...state,
        getReasonsLoading: false,
        getReasonsError: action.payload,
        getReasonsSuccess: null,
      };
    case adminActionTypes.GET_USERS_START:
      return {
        ...state,
        error: null,
        users: [],
        success: null,
        isLoading: true,
        pagination: {},
        editUserSuccess: null,
        getUserError: null,
      };
    case adminActionTypes.SETTINGS_START:
      return {
        ...state,
        settingsSuccess: null,
        settingsFailure: null,
        settingsLoading: true,
      };
    case adminActionTypes.SETTINGS_FAILURE:
      return {
        ...state,
        settingsSuccess: null,
        settingsFailure: action.payload,
        settingsLoading: true,
      };
    case adminActionTypes.SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        settingsSuccess: action.payload,
        settingsFailure: null,
        settingsLoading: false,
      };
    case adminActionTypes.EDIT_RATES_START:
      return {
        ...state,
        editRateLoading: true,
        editRateError: null,
        editRateSuccess: null,
      };
    case adminActionTypes.EDIT_RATES_SUCCESS:
      return {
        ...state,
        editRateLoading: false,
        editRateError: null,
        editRateSuccess: action.payload,
      };
    case adminActionTypes.EDIT_RATES_FAILURE:
      return {
        ...state,
        editRateLoading: false,
        editRateError: action.payload,
        editRateSuccess: null,
      };
    case adminActionTypes.EDIT_USERS_START:
      return {
        ...state,
        editUserLoading: true,
        editUserSuccess: null,
        editUserError: null,
      };
    case adminActionTypes.EDIT_USERS_SUCCESS:
      return {
        ...state,
        editUserLoading: false,
        editUserSuccess: action.payload,
        editUserError: null,
      };
    case adminActionTypes.EDIT_USERS_FAILURE:
      return {
        ...state,
        editUserLoading: false,
        editUserError: action.payload,
        editUserSuccess: null,
      };
    case adminActionTypes.BAN_USERS_START:
      return {
        ...state,
        banUserError: null,
        banUserSuccess: null,
        banUserLoading: true,
      };
    case adminActionTypes.BAN_USERS_SUCCESS:
      return {
        ...state,
        banUserError: null,
        banUserSuccess: action.payload,
        banUserLoading: false,
      };
    case adminActionTypes.BAN_USERS_FAILURE:
      return {
        ...state,
        banUserError: action.payload,
        banUserSuccess: null,
        banUserLoading: false,
      };
    case adminActionTypes.DELETE_USERS_START:
      return {
        ...state,
        deleteUserLoading: true,
        deleteUserError: null,
        deleteUserSuccess: null,
      };
    case adminActionTypes.DELETE_USERS_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: null,
        deleteUserSuccess: action.payload,
      };
    case adminActionTypes.DELETE_USERS_FAILURE:
      return {
        ...state,
        deleteUserLoading: false,
        deleteUserError: action.payload,
        deleteUserSuccess: null,
      };
    case adminActionTypes.GET_USER_BALANCE_START:
      return {
        ...state,
        balanceLoading: true,
        balance: [],
      };

    case adminActionTypes.GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        balanceLoading: false,
        balance: action.payload,
      };
    case adminActionTypes.GET_TRANSACTIONS_START:
      return {
        ...state,
        transactionLoading: true,
        transactionError: null,
        transactionSuccess: null,
        pagination: {},
      };
    case adminActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionLoading: false,
        transactionError: null,
        transactionSuccess: action.payload,
        transactions: action.payload,
      };
    case adminActionTypes.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactionLoading: false,
        transactionError: action.payload,
        transactionSuccess: null,
      };

    case adminActionTypes.GET_PENDING_PAYMENT_START:
      return {
        ...state,
        paymentLoading: true,
        pendingPaymentLoading: true,
        pendingPaymentError: null,
        pendingPaymentSuccess: null,
      };

    case adminActionTypes.GET_PENDING_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        pendingPaymentLoading: false,
        pendingPaymentError: null,
        pendingPaymentSuccess: action.payload,
        pendingPayments: action.payload,
      };

    case adminActionTypes.GET_PENDING_PAYMENT_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        pendingPaymentLoading: false,
        pendingPaymentError: action.payload,
        pendingPaymentSuccess: null,
      };

    case adminActionTypes.GET_APPROVED_PAYMENT_START:
      return {
        ...state,
        paymentLoading: true,
        approvedPaymentLoading: true,
        approvedPaymentError: null,
        approvedPaymentSuccess: null,
      };

    case adminActionTypes.GET_APPROVED_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        approvedPaymentLoading: false,
        approvedPaymentError: null,
        approvedPaymentSuccess: action.payload,
        approvedPayments: action.payload,
      };

    case adminActionTypes.GET_APPROVED_PAYMENT_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        approvedPaymentLoading: false,
        approvedPaymentError: action.payload,
        approvedPaymentSuccess: null,
      };

    case adminActionTypes.GET_REJECTED_PAYMENT_START:
      return {
        ...state,
        paymentLoading: true,
        rejectedPaymentLoading: true,
        rejectedPaymentError: null,
        rejectedPaymentSuccess: null,
      };

    case adminActionTypes.GET_REJECTED_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        rejectedPaymentLoading: false,
        rejectedPaymentError: null,
        rejectedPaymentSuccess: action.payload,
        rejectedPayments: action.payload,
      };

    case adminActionTypes.GET_REJECTED_PAYMENT_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        rejectedPaymentLoading: false,
        rejectedPaymentError: action.payload,
        rejectedPaymentSuccess: null,
      };

    case adminActionTypes.GET_CRYPTO_TRANSACTIONS_START:
      return {
        ...state,
        cryptoTransactionsLoading: true,
        cryptoTransactionsError: null,
        cryptoTransactionsSuccess: null,
        cryptoTransactions: [],
      };

    case adminActionTypes.GET_CRYPTO_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        cryptoTransactionsLoading: false,
        cryptoTransactionsError: false,
        cryptoTransactionsSuccess: true,
        cryptoTransactions: action.payload,
      };

    case adminActionTypes.GET_CRYPTO_TRANSACTIONS_FAILURE:
      return {
        ...state,
        cryptoTransactionsLoading: false,
        cryptoTransactionsError: action.payload,
        cryptoTransactionsSuccess: false,
      };

    case adminActionTypes.GET_CRYPTO_SELL_RATE_START:
      return {
        ...state,
        cryptoSellRateLoading: true,
        cryptoSellRateSuccess: null,
        cryptoSellRateError: null,
        cryptoSellRate: [],
      };

    case adminActionTypes.GET_CRYPTO_SELL_RATE_SUCCESS:
      return {
        ...state,
        cryptoSellRateLoading: false,
        cryptoSellRateSuccess: true,
        cryptoSellRateError: null,
        cryptoSellRate: action.payload,
      };

    case adminActionTypes.GET_CRYPTO_SELL_RATE_FAILURE:
      return {
        ...state,
        cryptoSellRateLoading: false,
        cryptoSellRateSuccess: false,
        cryptoSellRateError: action.payload,
      };

    case adminActionTypes.GET_CRYPTO_RATES_START:
      return {
        ...state,
        cryptoRatesLoading: true,
        cryptoRatesSuccess: null,
        cryptoRatesError: null,
        cryptoRates: [],
      };

    case adminActionTypes.GET_CRYPTO_RATES_SUCCESS:
      return {
        ...state,
        cryptoRatesLoading: false,
        cryptoRatesSuccess: true,
        cryptoRatesError: false,
        cryptoRates: action.payload,
      };

    case adminActionTypes.GET_CRYPTO_RATES_FAILURE:
      return {
        ...state,
        cryptoRatesLoading: false,
        cryptoRatesSuccess: false,
        cryptoRatesError: action.payload,
      };

    case adminActionTypes.GET_CURRENCY_RATES_START:
      return {
        ...state,
        currencyRatesLoading: true,
        currencyRatesSuccess: null,
        currencyRatesError: null,
        currencyRates: [],
      };

    case adminActionTypes.GET_CURRENCY_RATES_SUCCESS:
      return {
        ...state,
        currencyRatesLoading: false,
        currencyRatesSuccess: true,
        currencyRatesError: false,
        currencyRates: action.payload,
      };

    case adminActionTypes.GET_CURRENCY_RATES_FAILURE:
      return {
        ...state,
        currencyRatesLoading: false,
        currencyRatesSuccess: false,
        currencyRatesError: action.payload,
      };

    case adminActionTypes.GET_TRADES_START:
      return {
        ...state,
        tradeLoading: true,
        tradeError: null,
        tradeSuccess: null,
        pagination: {},
      };
    case adminActionTypes.GET_TRADES_SUCCESS:
      return {
        ...state,
        tradeLoading: false,
        tradeError: null,
        tradeSuccess: action.payload,
        trades: action.payload,
      };
    case adminActionTypes.GET_TRADES_FAILURE:
      return {
        ...state,
        tradeLoading: false,
        tradeError: action.payload,
        tradeSuccess: null,
      };
    case adminActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        success: action.payload,
        error: null,
        isLoading: false,
      };
    case adminActionTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case adminActionTypes.SET_PAGINATION_BUY:
      return {
        ...state,
        paginationBuy: action.payload,
      };

    case adminActionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        success: null,
      };
    case adminActionTypes.GET_SUBCATEGORY_START:
      return {
        ...state,
        subCategoryLoading: true,
        subCategoryError: null,
        subCategorySuccess: null,
      };
    case adminActionTypes.GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategories: action.payload,
        // rates: action.payload,
        subCategoryLoading: false,
        subCategoryError: null,
        subCategorySuccess: action.payload,
      };
    case adminActionTypes.GET_SUBCATEGORY_FAILURE:
      return {
        ...state,
        subCategoryLoading: false,
        subCategoryError: action.payload,
        subCategorySuccess: null,
        rates: [],
      };
    case adminActionTypes.GET_CATEGORY_START:
      return {
        ...state,
        categoryLoading: true,
        categoryError: null,
        categorySuccess: null,
      };
    case adminActionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        categoryLoading: false,
        categoryError: null,
        categorySuccess: action.payload,
      };
    case adminActionTypes.GET_CATEGORY_FAILURE:
      return {
        ...state,
        categoryLoading: false,
        categoryError: action.payload,
        categorySuccess: null,
        rates: [],
      };

    case adminActionTypes.GET_ALL_COINS_START:
      return {
        ...state,
        allCoinsLoading: true,
        allCoinsError: null,
        allCoinsSuccess: null,
      };
    case adminActionTypes.GET_ALL_COINS_SUCCESS:
      return {
        ...state,
        allCoins: action.payload,
        allCoinsLoading: false,
        allCoinsError: null,
        allCoinsSuccess: action.payload,
      };
    case adminActionTypes.GET_ALL_COINS_FAILURE:
      return {
        ...state,
        allCoinsLoading: false,
        allCoinsError: action.payload,
        allCoinsSuccess: null,
        allCoins: [],
      };

    default:
      return state;
  }
};
export default adminReducer;
