import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./libs";
import Noti from "./assets/here-i-am-449.mp3";
import { notification } from "antd";
import { IdleTimerProvider } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { signOut } from "./store/operator";

// const checkIfBeamIsSupported = () => "serviceWorker" in navigator || "PushManager" in window || "Notification" in window;

const App = () => {
  const [notificationMessage, setNotification] = useState({
    title: "",
    body: "",
  });
  const [isTokenFound, setTokenFound] = useState(false);
  const notiRef = useRef();

  let dispatch = useDispatch();

  const onIdle = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  // useEffect(() => {
  //   (async () => {
  //     if (checkIfBeamIsSupported()) {
  //       const PusherPushNotifications = require("@pusher/push-notifications-web");
  //       const beamsClient = new PusherPushNotifications.Client({
  //         instanceId: "76ece59f-2e35-4a46-9312-dca21a79df99",
  //       });
  //       beamsClient
  //         .start()
  //         .then((beamsClient) => beamsClient.getDeviceId())
  //         .then((deviceId) => {
  //           return setTokenFound({ found: true, token: deviceId });
  //         })
  //         .then(() => beamsClient.addDeviceInterest("trades"))
  //         .then(() => beamsClient.getDeviceInterests())
  //         .then((interests) => console.log("Current interests:", interests))
  //         .catch(console.error, "err");
  //     }
  //   })();
  // }, []);
  // const openNotification = () => {
  //   notification.info({
  //     message: "New Notification",
  //     description: notificationMessage.body,
  //   });
  // };
  // if (checkIfBeamIsSupported()) {
  //   navigator?.serviceWorker?.addEventListener("message", (event) => {
  //     setNotification({ ...event.data.notification });
  //     openNotification();
  //     notiRef?.current?.play();
  //   });
  // }
  return (
    <React.Fragment>
      <IdleTimerProvider timeout={1000 * 120} onIdle={onIdle}>
        <audio ref={notiRef} src={Noti} />
        <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
      </IdleTimerProvider>
    </React.Fragment>
  );
};

export default App;
