const adminActionTypes = {
  GET_USERS_START: "GET_USERS_START",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  EDIT_USERS_START: "EDIT_USERS_START",
  EDIT_USERS_SUCCESS: "EDIT_USERS_SUCCESS",
  EDIT_USERS_FAILURE: "EDIT_USERS_FAILURE",
  BAN_USERS_START: "BAN_USERS_START",
  BAN_USERS_SUCCESS: "BAN_USERS_SUCCESS",
  BAN_USERS_FAILURE: "BAN_USERS_FAILURE",
  DELETE_USERS_START: "DELETE_USERS_START",
  DELETE_USERS_SUCCESS: "DELETE_USERS_SUCCESS",
  DELETE_USERS_FAILURE: "DELETE_USERS_FAILURE",
  GET_TRANSACTIONS_START: "GET_TRANSACTIONS_START",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",

  GET_CRYPTO_TRANSACTIONS_START: "GET_CRYPTO_TRANSACTIONS_START",
  GET_CRYPTO_TRANSACTIONS_SUCCESS: "GET_CRYPTO_TRANSACTIONS_SUCCESS",
  GET_CRYPTO_TRANSACTIONS_FAILURE: "GET_CRYPTO_TRANSACTIONS_FAILURE",

  GET_TRADES_START: "GET_TRADES_START",
  GET_TRADES_SUCCESS: "GET_TRADES_SUCCESS",
  GET_TRADES_FAILURE: "GET_TRADES_FAILURE",

  GET_COMPLETED_TRADES_START: "GET_COMPLETED_TRADES_START",
  GET_COMPLETED_TRADES_SUCCESS: "GET_COMPLETED_TRADES_SUCCESS",
  GET_COMPLETED_TRADES_FAILURE: "GET_COMPLETED_TRADES_FAILURE",

  GET_PENDING_TRADES_START: "GET_PENDING_TRADES_START",
  GET_PENDING_TRADES_SUCCESS: "GET_PENDING_TRADES_SUCCESS",
  GET_PENDING_TRADES_FAILURE: "GET_PENDING_TRADES_FAILURE",

  GET_REJECTED_TRADES_START: "GET_REJECTED_TRADES_START",
  GET_REJECTED_TRADES_SUCCESS: "GET_REJECTED_TRADES_SUCCESS",
  GET_REJECTED_TRADES_FAILURE: "GET_REJECTED_TRADES_FAILURE",

  GET_WITHDRAWAL_REQUESTS_START: "GET_WITHDRAWAL_REQUESTS_START",
  GET_WITHDRAWAL_REQUESTS_SUCCESS: "GET_WITHDRAWAL_REQUESTS_SUCCESS",
  GET_WITHDRAWAL_REQUESTS_FAILURE: "GET_WITHDRAWAL_REQUESTS_FAILURE",

  GET_APPROVED_WITHDRAWAL_REQUESTS_START:
    "GET_APPROVED_WITHDRAWAL_REQUESTS_START",
  GET_APPROVED_WITHDRAWAL_REQUESTS_SUCCESS:
    "GET_APPROVED_WITHDRAWAL_REQUESTS_SUCCESS",
  GET_APPROVED_WITHDRAWAL_REQUESTS_FAILURE:
    "GET_APPROVED_WITHDRAWAL_REQUESTS_FAILURE",

  GET_PENDING_WITHDRAWAL_REQUESTS_START:
    "GET_PENDING_WITHDRAWAL_REQUESTS_START",
  GET_PENDING_WITHDRAWAL_REQUESTS_SUCCESS:
    "GET_PENDING_WITHDRAWAL_REQUESTS_SUCCESS",
  GET_PENDING_WITHDRAWAL_REQUESTS_FAILURE:
    "GET_PENDING_WITHDRAWAL_REQUESTS_FAILURE",

  GET_DECLINED_WITHDRAWAL_REQUESTS_START:
    "GET_DECLINED_WITHDRAWAL_REQUESTS_START",
  GET_DECLINED_WITHDRAWAL_REQUESTS_SUCCESS:
    "GET_DECLINED_WITHDRAWAL_REQUESTS_SUCCESS",
  GET_DECLINED_WITHDRAWAL_REQUESTS_FAILURE:
    "GET_DECLINED_WITHDRAWAL_REQUESTS_FAILURE",

  // paypal
  GET_PAYPAL_REQUESTS_START: "GET_PAYPAL_REQUESTS_START",
  GET_PAYPAL_REQUESTS_SUCCESS: "GET_PAYPAL_REQUESTS_SUCCESS",
  GET_PAYPAL_REQUESTS_FAILURE: "GET_PAYPAL_REQUESTS_FAILURE",

  GET_APPROVED_PAYPAL_REQUESTS_START: "GET_APPROVED_PAYPAL_REQUESTS_START",
  GET_APPROVED_PAYPAL_REQUESTS_SUCCESS: "GET_APPROVED_PAYPAL_REQUESTS_SUCCESS",
  GET_APPROVED_PAYPAL_REQUESTS_FAILURE: "GET_APPROVED_PAYPAL_REQUESTS_FAILURE",

  GET_PENDING_PAYPAL_REQUESTS_START: "GET_PENDING_PAYPAL_REQUESTS_START",
  GET_PENDING_PAYPAL_REQUESTS_SUCCESS: "GET_PENDING_PAYPAL_REQUESTS_SUCCESS",
  GET_PENDING_PAYPAL_REQUESTS_FAILURE: "GET_PENDING_PAYPAL_REQUESTS_FAILURE",

  GET_DECLINED_PAYPAL_REQUESTS_START: "GET_DECLINED_PAYPAL_REQUESTS_START",
  GET_DECLINED_PAYPAL_REQUESTS_SUCCESS: "GET_DECLINED_PAYPAL_REQUESTS_SUCCESS",
  GET_DECLINED_PAYPAL_REQUESTS_FAILURE: "GET_DECLINED_PAYPAL_REQUESTS_FAILURE",

  GET_REFERRALS_START: "GET_REFERRALS_START",
  GET_REFERRALS_SUCCESS: "GET_REFERRALS_SUCCESS",
  GET_REFERRALS_FAILURE: "GET_REFERRALS_FAILURE",

  GET_WALLET_SWAPS_START: "GET_WALLET_SWAPS_START",
  GET_WALLET_SWAPS_SUCCESS: "GET_WALLET_SWAPS_SUCCESS",
  GET_WALLET_SWAPS_FAILURE: "GET_WALLET_SWAPS_FAILURE",

  // cash app
  GET_CASHAPP_REQUESTS_START: "GET_CASHAPP_REQUESTS_START",
  GET_CASHAPP_REQUESTS_SUCCESS: "GET_CASHAPP_REQUESTS_SUCCESS",
  GET_CASHAPP_REQUESTS_FAILURE: "GET_CASHAPP_REQUESTS_FAILURE",

  GET_APPROVED_CASHAPP_REQUESTS_START: "GET_APPROVED_CASHAPP_REQUESTS_START",
  GET_APPROVED_CASHAPP_REQUESTS_SUCCESS:
    "GET_APPROVED_CASHAPP_REQUESTS_SUCCESS",
  GET_APPROVED_CASHAPP_REQUESTS_FAILURE:
    "GET_APPROVED_CASHAPP_REQUESTS_FAILURE",

  GET_PENDING_CASHAPP_REQUESTS_START: "GET_PENDING_CASHAPP_REQUESTS_START",
  GET_PENDING_CASHAPP_REQUESTS_SUCCESS: "GET_PENDING_CASHAPP_REQUESTS_SUCCESS",
  GET_PENDING_CASHAPP_REQUESTS_FAILURE: "GET_PENDING_CASHAPP_REQUESTS_FAILURE",

  GET_DECLINED_CASHAPP_REQUESTS_START: "GET_DECLINED_CASHAPP_REQUESTS_START",
  GET_DECLINED_CASHAPP_REQUESTS_SUCCESS:
    "GET_DECLINED_CASHAPP_REQUESTS_SUCCESS",
  GET_DECLINED_CASHAPP_REQUESTS_FAILURE:
    "GET_DECLINED_CASHAPP_REQUESTS_FAILURE",

  // usdt
  GET_USDT_REQUESTS_START: "GET_USDT_REQUESTS_START",
  GET_USDT_REQUESTS_SUCCESS: "GET_USDT_REQUESTS_SUCCESS",
  GET_USDT_REQUESTS_FAILURE: "GET_USDT_REQUESTS_FAILURE",

  GET_APPROVED_USDT_REQUESTS_START: "GET_APPROVED_USDT_REQUESTS_START",
  GET_APPROVED_USDT_REQUESTS_SUCCESS: "GET_APPROVED_USDT_REQUESTS_SUCCESS",
  GET_APPROVED_USDT_REQUESTS_FAILURE: "GET_APPROVED_USDT_REQUESTS_FAILURE",

  GET_PENDING_USDT_REQUESTS_START: "GET_PENDING_USDT_REQUESTS_START",
  GET_PENDING_USDT_REQUESTS_SUCCESS: "GET_PENDING_USDT_REQUESTS_SUCCESS",
  GET_PENDING_USDT_REQUESTS_FAILURE: "GET_PENDING_USDT_REQUESTS_FAILURE",

  GET_DECLINED_USDT_REQUESTS_START: "GET_DECLINED_USDT_REQUESTS_START",
  GET_DECLINED_USDT_REQUESTS_SUCCESS: "GET_DECLINED_USDT_REQUESTS_SUCCESS",
  GET_DECLINED_USDT_REQUESTS_FAILURE: "GET_DECLINED_USDT_REQUESTS_FAILURE",

  GET_PENDING_CRYPTO_TRANSACTIONS_START:
    "GET_PENDING_CRYPTO_TRANSACTIONS_START",
  GET_PENDING_CRYPTO_TRANSACTIONS_SUCCESS:
    "GET_PENDING_CRYPTO_TRANSACTIONS_SUCCESS",
  GET_PENDING_CRYPTO_TRANSACTIONS_FAILURE:
    "GET_PENDING_CRYPTO_TRANSACTIONS_FAILURE",

  GET_PROCESSED_CRYPTO_TRANSACTIONS_START:
    "GET_PROCESSED_CRYPTO_TRANSACTIONS_START",
  GET_PROCESSED_CRYPTO_TRANSACTIONS_SUCCESS:
    "GET_PROCESSED_CRYPTO_TRANSACTIONS_SUCCESS",
  GET_PROCESSED_CRYPTO_TRANSACTIONS_FAILURE:
    "GET_PROCESSED_CRYPTO_TRANSACTIONS_FAILURE",

  GET_CRYPTO_PROOFS_START: "GET_CRYPTO_PROOFS_START",
  GET_CRYPTO_PROOFS_SUCCESS: "GET_CRYPTO_PROOFS_SUCCESS",
  GET_CRYPTO_PROOFS_FAILURE: "GET_CRYPTO_PROOFS_FAILURE",

  GET_PENDING_CRYPTO_PROOFS_START: "GET_PENDING_CRYPTO_PROOFS_START",
  GET_PENDING_CRYPTO_PROOFS_SUCCESS: "GET_PENDING_CRYPTO_PROOFS_SUCCESS",
  GET_PENDING_CRYPTO_PROOFS_FAILURE: "GET_PENDING_CRYPTO_PROOFS_FAILURE",

  GET_PROCESSED_CRYPTO_PROOFS_START: "GET_PROCESSED_CRYPTO_PROOFS_START",
  GET_PROCESSED_CRYPTO_PROOFS_SUCCESS: "GET_PROCESSED_CRYPTO_PROOFS_SUCCESS",
  GET_PROCESSED_CRYPTO_PROOFS_FAILURE: "GET_PROCESSED_CRYPTO_PROOFS_FAILURE",

  GET_DECLINED_CRYPTO_PROOFS_START: "GET_DECLINED_CRYPTO_PROOFS_START",
  GET_DECLINED_CRYPTO_PROOFS_SUCCESS: "GET_PROCESSED_CRYPTO_PROOFS_SUCCESS",
  GET_DECLINED_CRYPTO_PROOFS_FAILURE: "GET_DECLINED_CRYPTO_PROOFS_FAILURE",

  GET_CRYPTO_SELL_RATE_START: "GET_CRYPTO_SELL_RATE_START",
  GET_CRYPTO_SELL_RATE_SUCCESS: "GET_CRYPTO_SELL_RATE_SUCCESS",
  GET_CRYPTO_SELL_RATE_FAILURE: "GET_CRYPTO_SELL_RATE_FAILURE",

  GET_CRYPTO_RATES_START: "GET_CRYPTO_RATES_START",
  GET_CRYPTO_RATES_SUCCESS: "GET_CRYPTO_RATES_SUCCESS",
  GET_CRYPTO_RATES_FAILURE: "GET_CRYPTO_RATES_FAILURE",

  GET_CURRENCY_RATES_START: "GET_CURRENCY_RATES_START",
  GET_CURRENCY_RATES_SUCCESS: "GET_CURRENCY_RATES_SUCCESS",
  GET_CURRENCY_RATES_FAILURE: "GET_CURRENCY_RATES_FAILURE",

  GET_SUBCATEGORY_START: "GET_SUBCATEGORY_START",
  GET_SUBCATEGORY_SUCCESS: "GET_SUBCATEGORY_SUCCESS",
  GET_SUBCATEGORY_FAILURE: "GET_SUBCATEGORY_FAILURE",

  GET_CATEGORY_START: "GET_CATEGORY_START",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",

  GET_GIFTCARD_START: "GET_GIFTCARD_START",
  GET_GIFTCARD_SUCCESS: "GET_GIFTCARD_SUCCESS",
  GET_GIFTCARD_FAILURE: "GET_GIFTCARD_FAILURE",

  GET_PROMO_CODE_START: "GET_PROMO_CODE_START",
  GET_PROMO_CODE_SUCCESS: "GET_PROMO_CODE_SUCCESS",
  GET_PROMO_CODE_FAILURE: "GET_PROMO_CODE_FAILURE",

  GET_BANNED_USERS_START: "GET_BANNED_USERS_START",
  GET_BANNED_USERS_SUCCESS: "GET_BANNED_USERS_SUCCESS",
  GET_BANNED_USERS_FAILURE: "GET_BANNED_USERS_FAILURE",

  GET_USER_BALANCE_START: "GET_USER_BALANCE_START",
  GET_USER_BALANCE_SUCCESS: "GET_USER_BALANCE_SUCCESS",
  GET_USER_BALANCE_FAILURE: "GET_USER_BALANCE_FAILURE",

  EDIT_RATES_START: "EDIT_RATES_START",
  EDIT_RATES_SUCCESS: "EDIT_RATES_SUCCESS",
  EDIT_RATES_FAILURE: "EDIT_RATES_FAILURE",

  FILTER_TRANSACTIONS_START: "FILTER_TRANSACTIONS_START",
  FILTER_TRANSACTIONS_SUCCESS: "FILTER_TRANSACTIONS_SUCCESS",
  FILTER_TRANSACTIONS_FAILURE: "FILTER_TRANSACTIONS_FAILURE",

  SETTINGS_START: "SETTINGS_START",
  SETTINGS_SUCCESS: "SETTINGS_SUCCESS",
  SETTINGS_FAILURE: "SETTINGS_FAILURE",

  GET_REASONS_START: "GET_REASONS_START",
  GET_REASONS_SUCCESS: "GET_REASONS_SUCCESS",
  GET_REASONS_FAILURE: "GET_REASONS_FAILURE",
  SET_PAGINATION: "SET_PAGINATION",
  GET_GIFTCODES_START: "GET_GIFTCODES_START",
  GET_GIFTCODES_SUCCESS: "GET_GIFTCODES_SUCCESS",
  GET_GIFTCODES_FAILURE: "GET_GIFTCODES_FAILURE",
  GET_TOPUP_START: "GET_TOPUP_START",
  GET_TOPUP_SUCCESS: "GET_TOPUP_SUCCESS",
  GET_TOPUP_FAILURE: "GET_TOPUP_FAILURE",
  GET_REFILL_START: "GET_REFILL_START",
  GET_REFILL_SUCCESS: "GET_REFILL_SUCCESS",
  GET_REFILL_FAILURE: "GET_REFILL_FAILURE",
  GET_BUY_TRADES_START: "GET_BUY_TRADES_START",
  GET_BUY_TRADES_SUCCESS: "GET_BUY_TRADES_SUCCESS",
  GET_BUY_TRADES_FAILURE: "GET_BUY_TRADES_FAILURE",
  SET_PAGINATION_BUY: "SET_PAGINATION_BUY",

  GET_PENDING_PAYMENT_START: "GET_PENDING_PAYMENT_START",
  GET_PENDING_PAYMENT_SUCCESS: "GET_PENDING_PAYMENT_SUCCESS",
  GET_PENDING_PAYMENT_FAILURE: "GET_PENDING_PAYMENT_FAILURE",

  GET_APPROVED_PAYMENT_START: "GET_APPROVED_PAYMENT_START",
  GET_APPROVED_PAYMENT_SUCCESS: "GET_APPROVED_PAYMENT_SUCCESS",
  GET_APPROVED_PAYMENT_FAILURE: "GET_APPROVED_PAYMENT_FAILURE",

  GET_REJECTED_PAYMENT_START: "GET_REJECTED_PAYMENT_START",
  GET_REJECTED_PAYMENT_SUCCESS: "GET_REJECTED_PAYMENT_SUCCESS",
  GET_REJECTED_PAYMENT_FAILURE: "GET_REJECTED_PAYMENT_FAILURE",

  GET_ALL_COINS_START: "GET_ALL_COINS_START",
  GET_ALL_COINS_SUCCESS: "GET_ALL_COINS_SUCCESS",
  GET_ALL_COINS_FAILURE: "GET_ALL_COINS_FAILURE",
};
export default adminActionTypes;
