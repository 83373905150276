import {
  getTransactionsFailure,
  getTransactionsStart,
  getTransactionsSuccess,
  getUsersFailure,
  getUsersStart,
  getUsersSuccess,
  getTradesStart,
  getTradesSuccess,
  getTradesFailure,
  getCompletedTradesStart,
  getCompletedTradesSuccess,
  getCompletedTradesFailure,
  getPendingTradesStart,
  getPendingTradesSuccess,
  getPendingTradesFailure,
  getRejectedTradesStart,
  getRejectedTradesSuccess,
  getRejectedTradesFailure,
  getSubCategoryStart,
  getSubCategorySuccess,
  getSubCategoryFailure,
  getUsersBalanceStart,
  getUsersBalanceSuccess,
  getUsersBalanceFailure,
  editUserStart,
  editUserSuccess,
  editUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  banUserStart,
  banUserSuccess,
  banUserFailure,
  getCategoryStart,
  getCategorySuccess,
  getCategoryFailure,
  editRateStart,
  editRateSuccess,
  editRateFailure,
  getSettingsStart,
  getSettingsSuccess,
  getSettingsFailure,
  getReasonsFailure,
  getReasonsSuccess,
  getReasonsStart,
  setPagination,
  getGiftcodesFailure,
  getGiftcodesStart,
  getGiftcodesSuccess,
  getTopupStart,
  getTopupSuccess,
  getRefillFailure,
  getRefillStart,
  getRefillSuccess,
  getTopupFailure,
  getBuyTradesFailure,
  getBuyTradesStart,
  setPaginationBuy,
  getBuyTradesSuccess,
  getGiftcardFailure,
  getGiftcardStart,
  getGiftcardSuccess,
  getPendingPaymentStart,
  getPendingPaymentSuccess,
  getPendingPaymentFailure,
  getApprovedPaymentStart,
  getApprovedPaymentSuccess,
  getApprovedPaymentFailure,
  getRejectedPaymentFailure,
  getRejectedPaymentStart,
  getRejectedPaymentSuccess,
  getCryptoTransactionsStart,
  getCryptoTransactionsSuccess,
  getCryptoTransactionsFailure,
  getCryptoRatesFailure,
  getCryptoRatesStart,
  getCryptoRatesSuccess,
  getCryptoSellRateFailure,
  getCryptoSellRateStart,
  getCryptoSellRateSuccess,
  getWithdrawalRequestsStart,
  getWithdrawalRequestsSuccess,
  getWithdrawalRequestsFailure,
  getPendingWithdrawalRequestsStart,
  getPendingWithdrawalRequestsSuccess,
  getPendingWithdrawalRequestsFailure,
  getApprovedWithdrawalRequestsStart,
  getApprovedWithdrawalRequestsSuccess,
  getApprovedWithdrawalRequestsFailure,
  getDeclinedWithdrawalRequestsStart,
  getDeclinedWithdrawalRequestsSuccess,
  getDeclinedWithdrawalRequestsFailure,
  getPendingCryptoTransactionsStart,
  getPendingCryptoTransactionsSuccess,
  getPendingCryptoTransactionsFailure,
  getProcessedCryptoTransactionsStart,
  getProcessedCryptoTransactionsSuccess,
  getProcessedCryptoTransactionsFailure,
  getPendingCryptoProofsStart,
  getPendingCryptoProofsSuccess,
  getProcessedCryptoProofsStart,
  getProcessedCryptoProofsSuccess,
  getPendingCryptoProofsFailure,
  getProcessedCryptoProofsFailure,
  getDeclinedCryptoProofsStart,
  getDeclinedCryptoProofsSuccess,
  getDeclinedCryptoProofsFailure,
  getCryptoProofsStart,
  getCryptoProofsSuccess,
  getCryptoProofsFailure,
  getAllCoinsStart,
  getAllCoinsSuccess,
  getAllCoinsFailure,
  getPaypalRequestsStart,
  getPaypalRequestsSuccess,
  getPaypalRequestsFailure,
  getApprovedPaypalRequestsStart,
  getApprovedPaypalRequestsSuccess,
  getApprovedPaypalRequestsFailure,
  getPendingPaypalRequestsStart,
  getPendingPaypalRequestsSuccess,
  getPendingPaypalRequestsFailure,
  getDeclinedPaypalRequestsStart,
  getDeclinedPaypalRequestsSuccess,
  getDeclinedPaypalRequestsFailure,
  getCashAppRequestsStart,
  getCashAppRequestsSuccess,
  getCashAppRequestsFailure,
  getApprovedCashAppRequestsStart,
  getApprovedCashAppRequestsSuccess,
  getApprovedCashAppRequestsFailure,
  getPendingCashAppRequestsStart,
  getPendingCashAppRequestsSuccess,
  getPendingCashAppRequestsFailure,
  getDeclinedCashAppRequestsStart,
  getDeclinedCashAppRequestsSuccess,
  getDeclinedCashAppRequestsFailure,
  getUsdtRequestsStart,
  getUsdtRequestsSuccess,
  getUsdtRequestsFailure,
  getApprovedUsdtRequestsStart,
  getApprovedUsdtRequestsSuccess,
  getApprovedUsdtRequestsFailure,
  getPendingUsdtRequestsStart,
  getPendingUsdtRequestsSuccess,
  getPendingUsdtRequestsFailure,
  getDeclinedUsdtRequestsStart,
  getDeclinedUsdtRequestsSuccess,
  getDeclinedUsdtRequestsFailure,
  getCurrencyRatesStart,
  getCurrencyRatesSuccess,
  getCurrencyRatesFailure,
  getPromoCodesStart,
  getPromoCodesSuccess,
  getPromoCodesFailure,
  getBannedUsersStart,
  getBannedUsersSuccess,
  getBannedUsersFailure,
  getReferralsStart,
  getReferralsSuccess,
  getReferralsFailure,
  getWalletSwapsStart,
  getWalletSwapsSuccess,
  getWalletSwapsFailure,
} from "../actions/admin.actions";
import { apiGet, apiPatch, apiDelete, apiPut, apiPost } from "../../libs/api";
import { message } from "antd";
import axios from "axios";

// get user details
export const getUsersDetails = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getUsersStart());
    try {
      const response = await apiGet(`user/list?page=${pageNum}&limit=${limit}`);
      if (response.statusCode === 200) {
        dispatch(getUsersSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
      return response.data.data;
    } catch (e) {
      dispatch(getUsersFailure(e));
    }
  };
};

export const getUsersDetailsForSearch = (search) => {
  return async (dispatch) => {
    dispatch(getUsersStart());
    try {
      const response = await apiGet(`user/list?search=${search}`);
      if (response.statusCode === 200) {
        dispatch(getUsersSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
      return response.data.data;
    } catch (e) {
      dispatch(getUsersFailure(e));
    }
  };
};

export const filterUsersDetails = (name, query) => {
  return (dispatch) => {
    dispatch(getUsersStart(name, query));
    return (
      query &&
      apiGet(`search-user?${name}=${query}`)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(getUsersSuccess(response.data));
            setPagination(response.data);
            const paginationData = {};
            paginationData.total = response.data.totalUsers;
            paginationData.current = response.data.currentPage;
            paginationData.showSizeChanger = false;
            dispatch(setPagination(paginationData));
          }
          return response.data;
        })
        .catch((e) => {
          dispatch(getUsersFailure(e));
        })
    );
  };
};

// admin staff
export const getUsersDetailsRole = (role, pageNum) => {
  return async (dispatch) => {
    dispatch(getUsersStart(role, pageNum));
    try {
      const response = await apiGet(
        // `users?userRole=${role}&currentPage=${pageNum}&limit=10000`
        `staff`
      );
      // console.log(response.data.data);
      if (response.statusCode === 200) {
        dispatch(getUsersSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.data.length;
        paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = false;
        dispatch(setPagination(paginationData));
      }
      return response.data.data;
    } catch (e) {
      dispatch(getUsersFailure(e));
    }
  };
};

export const getPendingPayment = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingPaymentStart());
    try {
      const response = await apiGet(
        `withdrawal?status=pending&page=${page}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingPaymentSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingPaymentFailure(e));
    }
  };
};

export const getRejectedPayment = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getRejectedPaymentStart());
    try {
      const response = await apiGet(
        `withdrawal?status=declined&page=${page}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getRejectedPaymentSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getRejectedPaymentFailure(e));
    }
  };
};

export const getApprovedPayment = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getApprovedPaymentStart());
    try {
      const response = await apiGet(
        `withdrawal?status=completed&page=${page}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getApprovedPaymentSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getApprovedPaymentFailure(e));
    }
  };
};

export const getAllTransactions = (status, pageNum, name = "", limit = 10) => {
  return async (dispatch) => {
    dispatch(getTransactionsStart(status));
    try {
      const response = await apiGet(
        `${name}transactions?status=${status}&currentPage=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        const {
          totalUSDTransactions,
          totalGHSTransactions,
          totalTransactions,
        } = response.data;
        const totalTransacts =
          totalUSDTransactions || totalGHSTransactions || totalTransactions;

        dispatch(getTransactionsSuccess(response.data));
        setPagination(response.data);
        const paginationData = {};
        paginationData.total = totalTransacts;
        paginationData.current = response.data.currentPage;
        paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getTransactionsFailure(e));
    }
  };
};

export const getAllCryptoTransactions = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getCryptoTransactionsStart());
    try {
      const response = await apiGet(
        `crypto/transactions?page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getCryptoTransactionsSuccess(response.data.data));
        const paginationData = {};
        paginationData.total = response.data.totalCount;

        // paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getCryptoTransactionsFailure(e));
    }
  };
};

export const getSellCryptoRate = () => {
  return async (dispatch) => {
    dispatch(getCryptoSellRateStart());
    try {
      const response = await axios.get(
        "https://api.1minutepay.online/api/v1/rate/fetch?currency=USDNGN&rateStatus=sell"
      );
      // console.log(response);
      if (response.status === 200) {
        dispatch(getCryptoSellRateSuccess(response.data.data));
      }
    } catch (e) {
      dispatch(getCryptoSellRateFailure(e));
    }
  };
};

export const getBuyCryptoRate = () => {
  return async (dispatch) => {
    dispatch(getCryptoRatesStart());
    try {
      const response = await axios.get(
        "https://api.1minutepay.online/api/v1/rate/fetch?currency=USDNGN&rateStatus=buy"
      );
      // console.log(response);
      if (response.status === 200) {
        dispatch(getCryptoRatesSuccess(response.data.data));
      }
    } catch (e) {
      dispatch(getCryptoRatesFailure(e));
    }
  };
};

export const getCryptoRates = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getCryptoRatesStart());
    try {
      const response = await axios.get(
        `https://api.1minutepay.online/api/v1/admin/crypto/crypto-rate?page=${page}&limit=${limit}`
      );
      // console.log(response);
      if (response.status === 200) {
        dispatch(getCryptoRatesSuccess(response.data.data));
        // when the response gets over 10, we can then add pagination
      }
    } catch (e) {
      dispatch(getCryptoRatesFailure(e));
    }
  };
};

export const getCurrencyRates = () => {
  return async (dispatch) => {
    dispatch(getCurrencyRatesStart());
    try {
      const response = await axios.get(
        "https://api.1minutepay.online/api/v1/rate"
      );

      if (response.status === 200) {
        dispatch(getCurrencyRatesSuccess(response.data.data));
      }
    } catch (e) {
      dispatch(getCurrencyRatesFailure(e));
    }
  };
};

// gets all gift card orders
export const getAllTrades = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getTradesStart());
    try {
      const response = await apiGet(
        `card/all-orders?page=${page}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getTradesSuccess(response.data.data));
        setPagination(response.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getTradesFailure(e));
    }
  };
};

export const getCompletedTrades = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getCompletedTradesStart());
    try {
      const response = await apiGet(
        // `trades?status=${status}&currentPage=${pageNum}&limit=${limit}`
        `card/completed-orders?page=${page}&limit=${limit}`
      );
      // console.log(response.data.data);
      if (response.statusCode === 200) {
        dispatch(getCompletedTradesSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getCompletedTradesFailure(e));
    }
  };
};

// pending giftcard trades
export const getPendingTrades = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingTradesStart(pageNum));
    try {
      const response = await apiGet(
        `card/pending-orders?page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingTradesSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingTradesFailure(e));
    }
  };
};

export const getRejectedTrades = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getRejectedTradesStart);
    try {
      const response = await apiGet(
        `card/rejected-orders?page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getRejectedTradesSuccess(response.data.data));
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getRejectedTradesFailure(e));
    }
  };
};

export const getAllReferrals = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getReferralsStart());
    try {
      const response = await apiGet(`referral?page=${pageNum}&limit=${limit}`);
      if (response.statusCode === 200) {
        dispatch(getReferralsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getReferralsFailure(e));
    }
  };
};

export const getAllWalletSwaps = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getWalletSwapsStart());
    try {
      const response = await apiGet(
        `transaction?page=${pageNum}&limit=${limit}&actualCategory=swap`
      );
      if (response.statusCode === 200) {
        dispatch(getWalletSwapsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getWalletSwapsFailure(e));
    }
  };
};

// gets all withdrawal requests
export const getAllWithdrawalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getWithdrawalRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/requests?page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getWithdrawalRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getWithdrawalRequestsFailure(e));
    }
  };
};

export const getProcessedWithdrawalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getApprovedWithdrawalRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/requests?status=processed&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getApprovedWithdrawalRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getApprovedWithdrawalRequestsFailure(e));
    }
  };
};

// pending withdrawal requests
export const getPendingWithdrawalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingWithdrawalRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/requests?status=pending&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingWithdrawalRequestsSuccess(response?.data?.data));
        setPagination(response?.data?.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingWithdrawalRequestsFailure(e));
    }
  };
};

export const getDeclinedWithdrawalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getDeclinedWithdrawalRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/requests?status=declined&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getDeclinedWithdrawalRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getDeclinedWithdrawalRequestsFailure(e));
    }
  };
};

// paypal
export const getAllPaypalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPaypalRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/options?option=paypal&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPaypalRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPaypalRequestsFailure(e));
    }
  };
};

export const getProcessedPaypalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getApprovedPaypalRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/options?option=paypal&status=processed&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getApprovedPaypalRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getApprovedPaypalRequestsFailure(e));
    }
  };
};

export const getPendingPaypalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingPaypalRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/options?option=paypal&status=pending&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingPaypalRequestsSuccess(response?.data?.data));
        setPagination(response?.data?.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingPaypalRequestsFailure(e));
    }
  };
};

export const getDeclinedPaypalRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getDeclinedPaypalRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/options?option=paypal&status=declined&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getDeclinedPaypalRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getDeclinedPaypalRequestsFailure(e));
    }
  };
};

// cashapp
export const getAllCashAppRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getCashAppRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/options?option=cashapp&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getCashAppRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getCashAppRequestsFailure(e));
    }
  };
};

export const getProcessedCashAppRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getApprovedCashAppRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/options?option=cashapp&status=processed&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getApprovedCashAppRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getApprovedCashAppRequestsFailure(e));
    }
  };
};

export const getPendingCashAppRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingCashAppRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/options?option=cashapp&status=pending&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingCashAppRequestsSuccess(response?.data?.data));
        setPagination(response?.data?.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingCashAppRequestsFailure(e));
    }
  };
};

export const getDeclinedCashAppRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getDeclinedCashAppRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/options?option=cashapp&status=declined&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getDeclinedCashAppRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getDeclinedCashAppRequestsFailure(e));
    }
  };
};

// usdt
export const getAllUsdtRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getUsdtRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/options?option=usdt&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getUsdtRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getUsdtRequestsFailure(e));
    }
  };
};

export const getProcessedUsdtRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getApprovedUsdtRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/options?option=usdt&status=processed&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getApprovedUsdtRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getApprovedUsdtRequestsFailure(e));
    }
  };
};

export const getPendingUsdtRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingUsdtRequestsStart());
    try {
      const response = await apiGet(
        `withdrawal/options?option=usdt&status=pending&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingUsdtRequestsSuccess(response?.data?.data));
        setPagination(response?.data?.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingUsdtRequestsFailure(e));
    }
  };
};

export const getDeclinedUsdtRequests = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getDeclinedUsdtRequestsStart(pageNum));
    try {
      const response = await apiGet(
        `withdrawal/options?option=usdt&status=declined&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getDeclinedUsdtRequestsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getDeclinedUsdtRequestsFailure(e));
    }
  };
};

// pending crypto transactions
export const getPendingCryptoTransactions = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingCryptoTransactionsStart(pageNum));
    try {
      const response = await apiGet(
        `crypto/transactions?status=pending&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getPendingCryptoTransactionsSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingCryptoTransactionsFailure(e));
    }
  };
};

// processed crypto transactions
export const getProcessedCryptoTransactions = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getProcessedCryptoTransactionsStart(pageNum));
    try {
      const response = await apiGet(
        `crypto/transactions?status=processed&page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getProcessedCryptoTransactionsSuccess(response.data.data));
        setPagination(response.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getProcessedCryptoTransactionsFailure(e));
    }
  };
};
// pending crypto proofs
export const getPendingCryptoProofs = (pageNum, limit = 10) => {
  return async (dispatch) => {
    dispatch(getPendingCryptoProofsStart(pageNum));
    try {
      const response = await apiGet(`crypto/uploaded-proof`);
      if (response.statusCode === 200) {
        const pendingProofs = response.data.data.filter(
          (req) => req.status === "pending"
        );

        dispatch(getPendingCryptoProofsSuccess(pendingProofs));
        setPagination(pendingProofs);
        const paginationData = {};
        paginationData.total = pendingProofs;
        paginationData.current = response.data.currentPage;
        paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPendingCryptoProofsFailure(e));
    }
  };
};
// processed crypto proofs
export const getProcessedCryptoProofs = (pageNum, limit = 10) => {
  return async (dispatch) => {
    dispatch(getProcessedCryptoProofsStart(pageNum));
    try {
      const response = await apiGet(`crypto/uploaded-proof`);
      if (response.statusCode === 200) {
        const processedProofs = response.data.data.filter(
          (req) => req.status === "processed"
        );

        dispatch(getProcessedCryptoProofsSuccess(processedProofs));
        setPagination(processedProofs);
        const paginationData = {};
        paginationData.total = processedProofs;
        paginationData.current = response.data.currentPage;
        paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getProcessedCryptoProofsFailure(e));
    }
  };
};
// pending crypto proofs
export const getDeclinedCryptoProofs = (pageNum, limit = 10) => {
  return async (dispatch) => {
    dispatch(getDeclinedCryptoProofsStart(pageNum));
    try {
      const response = await apiGet(`crypto/uploaded-proof`);
      if (response.statusCode === 200) {
        const declinedProofs = response.data.data.filter(
          (req) => req.status === "declined"
        );

        dispatch(getDeclinedCryptoProofsSuccess(declinedProofs));
        setPagination(declinedProofs);
        const paginationData = {};
        paginationData.total = declinedProofs;
        paginationData.current = response.data.currentPage;
        paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getDeclinedCryptoProofsFailure(e));
    }
  };
};

// all crypto proofs
export const getAllCryptoProofs = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getCryptoProofsStart(pageNum));
    try {
      const response = await apiGet(
        `crypto/uploaded-proof?page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        const cryptoProofs = response.data.data;

        dispatch(getCryptoProofsSuccess(cryptoProofs));
        setPagination(cryptoProofs);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        // paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getCryptoProofsFailure(e));
    }
  };
};

export const getAllBuyTrades = (status, pageNum, limit = 20) => {
  return async (dispatch) => {
    dispatch(getBuyTradesStart(status, pageNum));
    try {
      const response = await apiGet(
        `gift-card-purchases?status=${status}&currentPage=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getBuyTradesSuccess(response.data.giftCardPurchases));
        const paginationData = {};
        paginationData.total = response.data.totalGiftCardPurchases;
        paginationData.current = response.data.currentPage;
        paginationData.pageSize = limit;
        paginationData.showSizeChanger = true;
        dispatch(setPaginationBuy(paginationData));
      }
    } catch (e) {
      dispatch(getBuyTradesFailure(e));
    }
  };
};

export const filterTradesDetails = (name, query, limit = 200) => {
  return (dispatch) => {
    dispatch(getTradesStart(name, query));
    return (
      query &&
      apiGet(`search-trades/?${name}=${query}&limit=${limit}`)
        .then((response) => {
          if (response.statusCode === 200) {
            dispatch(getTradesSuccess(response.data));
            setPagination(response.data);
            const paginationData = {};
            paginationData.total = response.data.totalTrades;
            paginationData.current = response.data.currentPage;
            paginationData.pageSize = limit;
            paginationData.showSizeChanger = true;
            dispatch(setPagination(paginationData));
          }
          return response.data;
        })
        .catch((e) => {
          dispatch(getTradesFailure(e));
        })
    );
  };
};

export const getAllSubCategories = (pageNum = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getSubCategoryStart());
    try {
      const response = await apiGet(
        `giftcard/get-sub-categories?page=${pageNum}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getSubCategorySuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getSubCategoryFailure(e));
    }
  };
};

export const getAllGiftCards = () => {
  return async (dispatch) => {
    dispatch(getGiftcardStart());
    try {
      const response = await apiGet(`giftcard/get-sub-categories`);
      // console.log(response.data.data);
      if (response.statusCode === 200) {
        dispatch(getGiftcardSuccess(response.data.data));
      }
    } catch (e) {
      dispatch(getGiftcardFailure(e));
    }
  };
};

export const getAllPromoCodes = () => {
  return async (dispatch) => {
    dispatch(getPromoCodesStart());
    try {
      const response = await apiGet(`promo`);
      // console.log(response.data.data);
      if (response.statusCode === 200) {
        dispatch(getPromoCodesSuccess(response.data.data));

        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getPromoCodesFailure(e));
    }
  };
};

// banned users
export const getAllBannedUsers = () => {
  return async (dispatch) => {
    dispatch(getBannedUsersStart());
    try {
      const response = await apiGet(`user/banned`);
      if (response.statusCode === 200) {
        dispatch(getBannedUsersSuccess(response.data.data));

        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getBannedUsersFailure(e));
    }
  };
};

export const getAllCategories = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getCategoryStart());
    try {
      const response = await apiGet(
        `giftcard/get-gift-cards?page=${page}&limit=${limit}`
      );
      // console.log(response);
      if (response.statusCode === 200) {
        dispatch(getCategorySuccess(response.data.data));

        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getCategoryFailure(e));
    }
  };
};

export const getUsersBalance = () => {
  return async (dispatch) => {
    dispatch(getUsersBalanceStart());
    try {
      const response = await apiGet(`main`);
      // console.log(response.data.data);
      if (response.data.status === "success") {
        dispatch(getUsersBalanceSuccess(response.data.data));
      }
    } catch (e) {
      dispatch(getUsersBalanceFailure(e));
    }
  };
};

export const editUserInfo = (userInfo, id) => {
  return async (dispatch) => {
    dispatch(editUserStart(userInfo));
    try {
      const response = await apiPut(`user/${id}`, userInfo);
      if (response.statusCode === 200) {
        dispatch(editUserSuccess("User Info Edited Successfully"));
        getUsersDetails();
      }
    } catch (e) {
      dispatch(editUserFailure(e));
    }
  };
};
export const editUserEmail = (id, userInfo) => {
  return async (dispatch) => {
    dispatch(editUserStart(userInfo));
    try {
      const response = await apiPut(`admin-update-user/${id}`, userInfo);
      if (response.statusCode === 200) {
        dispatch(editUserSuccess("User Info Edited Successfully"));
        getUsersDetails();
      }
    } catch (e) {
      dispatch(editUserFailure());
    }
  };
};

export const banUser = (id) => {
  return async (dispatch) => {
    dispatch(banUserStart());
    try {
      const response = await apiPost(`user/ban-user/${id}`);
      if (response.statusCode === 200) {
        dispatch(banUserSuccess(response.data));
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
      return response;
    } catch (e) {
      dispatch(banUserFailure(e));
    }
  };
};

// edit giftcards rate
export const editRate = (id, rate) => {
  // console.log(id, rate);
  return async (dispatch) => {
    dispatch(editRateStart(rate));
    try {
      const response = await apiPatch(
        `giftcard/update-sub-categories/${id}`,
        rate
      );
      if (response.statusCode === 200) {
        if (response.data.status === "failed") {
          message.error(response.data.message);
        } else {
          message.success(response.data.message);
          dispatch(editRateSuccess(response.data.data));
        }
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      dispatch(editRateFailure(e));
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch(deleteUserStart());
    try {
      const response = await apiDelete(`user/delete-user/${id}`);
      if (response.statusCode === 200) {
        dispatch(deleteUserSuccess(response.data));
      }
    } catch (e) {
      dispatch(deleteUserFailure(e));
    }
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    dispatch(getSettingsStart());
    try {
      const response = await apiGet(`settings/get-settings`);
      // console.log(response);
      if (response.statusCode === 200) {
        dispatch(getSettingsSuccess(response.data?.data));
        getUsersDetails();
      }
    } catch (e) {
      dispatch(getSettingsFailure(e));
    }
  };
};
// rejection reasons
export const getReasons = () => {
  return async (dispatch) => {
    dispatch(getReasonsStart());
    try {
      const response = await apiGet(`rejection`);
      // console.log(response);
      if (response.statusCode === 200) {
        dispatch(getReasonsSuccess(response.data.data));
      }
    } catch (e) {
      dispatch(getReasonsFailure(e));
    }
  };
};
export const getGiftcodes = (pageNum) => {
  return async (dispatch) => {
    dispatch(getGiftcodesStart());
    try {
      const response = await apiGet(`gift-codes?currentPage=${pageNum}`);
      if (response.statusCode === 200) {
        dispatch(getGiftcodesSuccess(response.data.giftCodes));
        setPagination(response.data);
        const paginationData = {};
        paginationData.total = response.data.totalGiftCodes;
        paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = false;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getGiftcodesFailure(e));
    }
  };
};

export const getTopUp = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getTopupStart());
    try {
      const response = await apiGet(`topup?page=${page}&limit=${limit}`);
      if (response.statusCode === 200) {
        dispatch(getTopupSuccess(response.data.data));
        setPagination(response.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;

        // paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = false;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getTopupFailure(e));
    }
  };
};

export const getRefill = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getRefillStart());
    try {
      const response = await apiGet(`refill?page=${page}&limit=${limit}`);
      // console.log(response);
      if (response.statusCode === 200) {
        dispatch(getRefillSuccess(response.data.data));
        setPagination(response.data.data);
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        // paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = false;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getRefillFailure(e));
    }
  };
};

export const searchGiftCards = (id) => {
  return async (dispatch) => {
    dispatch(getGiftcardStart(id));
    try {
      const response = await apiGet(`gift-card/${id}`);
      if (response.statusCode === 200) {
        dispatch(getGiftcardSuccess([response.data]));
      }
    } catch (e) {
      dispatch(getGiftcardFailure(e));
    }
  };
};
export const getGiftcode = (id) => {
  return async (dispatch) => {
    dispatch(getGiftcodesStart());
    try {
      const response = await apiGet(`gift-code/${id}`);
      if (response.statusCode === 200) {
        dispatch(getGiftcodesSuccess([response.data.giftCode]));
      }
    } catch (e) {
      dispatch(getGiftcodesFailure(e));
    }
  };
};
export const getGiftcodeByUsername = (username, pageNum) => {
  return async (dispatch) => {
    dispatch(getGiftcodesStart());
    try {
      const response = await apiGet(
        `gift-codes-username?username=${username}&currentPage=${pageNum}`
      );
      if (response.statusCode === 200) {
        dispatch(getGiftcodesSuccess(response.data.giftCodes));
        const paginationData = {};
        paginationData.total = response.data.totalGiftCodes;
        paginationData.current = response.data.currentPage;
        paginationData.showSizeChanger = false;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getGiftcodesFailure(e));
    }
  };
};

export const getAllCoins = (page = 0, limit = 10) => {
  return async (dispatch) => {
    dispatch(getAllCoinsStart());
    try {
      const response = await apiGet(
        `crypto/all-coins?page=${page}&limit=${limit}`
      );
      if (response.statusCode === 200) {
        dispatch(getAllCoinsSuccess(response.data.data));
        const paginationData = {};
        paginationData.total = response.data.totalCount;
        paginationData.showSizeChanger = true;
        dispatch(setPagination(paginationData));
      }
    } catch (e) {
      dispatch(getAllCoinsFailure(e));
    }
  };
};
