import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { accessToken } from "../../store/selectors/auth.selector";

const AuthGuard = ({ children }) => {
  const state = useSelector(accessToken);
  if (!state) {
    return <Redirect to="/login" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
