import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "./constants";
import { store } from "../store/index";
import authActionTypes from "../store/types/auth.type";
import { createAxiosResponseInterceptor } from "../store/operator/auth.op";
import {
  setUserAccessToken,
  setUserRefreshToken,
} from "../store/actions/auth.actions";
store.subscribe(listener);

function select(state) {
  return state.user.accessToken;
}
export const Axios = axios.create({
  baseURL: baseUrl,
  headers: { Authorization: `Bearer ${select(store.getState())}` },
});

export const Token = () => {
  return select(store.getState());
};
function listener() {
  let token = select(store.getState());
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const json = {
  "content-type": "application/json",
  Accept: "application/json",
  // "Content-type": "multipart/form-data",
};
const formData = {
  "content-type": "multipart/form-data",
  Accept: "multipart/form-data",
  // "Content-type": "multipart/form-data",
};
function setHeaders(headers = false, headerData) {
  if (headers) {
    headerData = { ...headerData, ...headers };
  }
  return headerData;
}
function setBody(method, data) {
  if (method === "get" || method === "delete") {
    return { params: data };
  } else {
    return { data: data };
  }
}

function setUrl(url) {
  return `${baseUrl}/${url}`;
}
function callApi(url, data, method, header, headerData) {
  let headers = setHeaders(header, headerData);
  let body = setBody(method, data);
  let apiUrl = setUrl(url);

  return new Promise((resolve, reject) => {
    axios({
      url: apiUrl,
      ...body,
      headers: headers,
      method: method,
    })
      .then((data) => {
        resolve({ statusCode: data.status, ...data });
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
}

export const apiGet = (url, data, header = false, headerData = json) => {
  return callApi(url, data, "get", header, headerData);
};

export const apiPostFD = (url, data, header = false, headerData = formData) => {
  return callApi(url, data, "post", header, headerData);
};
export const apiPost = (url, data, header = false, headerData = json) => {
  return callApi(url, data, "post", header, headerData);
};
export const apiPatch = (url, data, header = false, headerData = json) => {
  return callApi(url, data, "patch", header, headerData);
};
export const apiPutFD = (url, data, header = false, headerData = formData) => {
  return callApi(url, data, "put", header, headerData);
};
export const apiPut = (url, data, header = false, headerData = json) => {
  return callApi(url, data, "put", header, headerData);
};

export const apiDelete = (url, data, header = false, headerData = json) => {
  return callApi(url, data, "delete", header, headerData);
};

export const useFetch = (url, options) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, options);
        const data = await res.json();
        setResponse(data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { response, error, isLoading };
};
const handleError = (error) => {
  const { dispatch, getState } = store;
  if (error?.response?.status === 401) {
    const store = getState();
    const auth = store.user;
    // const accessToken = auth?.accessToken ?? "";
    const refresh = auth?.refreshToken ?? "";
    apiPost(`refresh`, { refreshToken: refresh })
      .then(({ data }) => {
        dispatch(setUserAccessToken(data.accessToken));
        dispatch(setUserRefreshToken(data.refreshToken));
        window.location.reload();
      })
      .catch((e) => (window.location.href = "/"));
  }
  let errorResponseData;
  let status;

  if (error.response !== undefined) {
    errorResponseData = error.response.data;
    status = error.response.status;
  } else if (error.data !== undefined) {
    errorResponseData = error.data;
    status = error.status;
  } else {
    return { statusCode: 500, status: "error", message: error.message };
  }
  return { statusCode: status, ...errorResponseData };
};
